import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet-async";
import Button from "../Button/Button";
import "./UniqueJob.scss";
import Form from "../Form/Form";
import Carousel from "../Home/Carousel/Carousel";
import { Route, Link, Routes, useParams } from "react-router-dom";
import { addLanguageSuffix } from "../../helpers/addLanguageSuffix";
import LocalizedStrings from "react-localization";

const API_URL = process.env.REACT_APP_API_URL;

let strings = new LocalizedStrings({
  en: {
    pageTitle: "Attiva - Gas welding...",
    breadCrumbsText: "Metal inert gas (MIG) and metal active gas (MAG) welding",
    jobBoxText: "Job Summary:",

    detail1Title: "Seats available:",
    detail1Value: "4 seats",

    detail2Title: "The experience:",
    detail2Value: ">3 years",

    detail3Title: "Location:",
    detail3Value: "Via Garofoli, Italia",

    detail4Title: "Category:",
    detail4Value: "Welders",

    jobDescriptionTitle: "Job description:",
    jobDescriptionValue1:
      "We are looking for serious and professional figures to fill the role of truck driver (dependent on goods movements) with the use of a walking stick requiring a clear title",
    jobDescriptionValue2:
      "Professional skills and skills, optimal use of work tools and care of the work environment, compliance with company rules and procedures are required.",

    weOfferTitle: "We offer:",
    weOfferValue1: "Transport Romania - Italy and local",
    weOfferValue2: "Service car - insured by the company",
    weOfferValue3: "Accommodation - provided by the company",
    weOfferValue4: "One meal a day",

    requirementsTitle: "Requirements:",
    requirementsValue1: "Experience in the field, minimum 3 years",
    requirementsValue2: "Immediate availability",
    requirementsValue3: "Driver's license",

    moreQuestionsTitle: "Are you still undecided?",
    moreQuestionsSubtitle:
      "We can help you find your job according to your profile",

    callNowBtnText: "Call now",
    callNowBtnClause: "*Telephone rates apply",
  },
  ro: {
    jobBoxText: "Sumar job:",

    detail1Title: "Locuri disponibile:",
    detail2Title: "Experiență:",
    detail3Title: "Locație:",
    detail4Title: "Categoria:",

    jobDescriptionTitle: "Descrierea jobului:",
    weOfferTitle: "Oferim:",
    requirementsTitle: "Cerințe:",
    moreQuestionsTitle: "Esti încă indecis?",
    moreQuestionsSubtitle:
      "Te putem ajuta noi sa-ti gasesti jobul dupa profilul tau",

    callNowBtnText: "Sună acum",
    callNowBtnClause: "*Se aplică tarife telefonice",
  },

  it: {
    jobBoxText: "Riepilogo del lavoro:",

    detail1Title: "Posti disponibili:",
    detail2Title: "L'esperienza:",
    detail3Title: "Posizione:",
    detail4Title: "Categoria:",
    jobDescriptionTitle: "Descrizione del lavoro:",
    weOfferTitle: "Offriamo:",

    requirementsTitle: "Requisiti:",

    moreQuestionsTitle: "Sei ancora indeciso?",
    moreQuestionsSubtitle:
      "Possiamo aiutarti a trovare il tuo lavoro in base al tuo profilo",

    callNowBtnText: "Chiamaci ora",
    callNowBtnClause: "*Si applicano le tariffe telefoniche",
  },
});

const UniqueJob = () => {
  const [local, setLocal] = useState(strings);
  const [uniqueJob, setUniqueJob] = useState();
  const getUniqueJob = async () => {
    await fetch(`${API_URL}/unique_jobs/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setUniqueJob(data);
        setTitle("Attiva - " + (data && data[addLanguageSuffix("title")]));
      });
  };

  const { id } = useParams();
  console.log(id);

  const [pageTitle, setTitle] = useState("Attiva");

  // "Attiva - " + uniqueJob && uniqueJob[addLanguageSuffix("title")]

  const [jobTypes, setJobTypes] = useState([]);
  const getJobTypes = async () => {
    const response = await fetch(`${API_URL}/job_types`);
    const data = await response.json();
    setJobTypes(data.job_types);
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
    getUniqueJob();
    getJobTypes();
  }, []);

  return (
    <div className="UniqueJob" data-testid="Job">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <BreadCrumbs
        items={[
          {
            text: uniqueJob && uniqueJob[addLanguageSuffix("title")],
          },
        ]}
      />
      <div className="container">
        <div className="job-box">
          <div className="job-box__title">
            {uniqueJob && uniqueJob[addLanguageSuffix("title")]}
          </div>
          <br></br>
          <div className="job-box__text">{strings.jobBoxText}</div>
          <div className="job-box__details">
            <div className="job-box__details__item">
              <div className="job-box__details__item__title">
                {strings.detail2Title}
              </div>
              <div className="job-box__details__item__value">
                {uniqueJob && uniqueJob[addLanguageSuffix("experience")]}
              </div>
            </div>
            <div className="job-box__details__item">
              <div className="job-box__details__item__title">
                {strings.detail3Title}
              </div>
              <div className="job-box__details__item__value">
                {uniqueJob &&
                  uniqueJob[addLanguageSuffix("location_exact")] +
                    ", " +
                    uniqueJob[addLanguageSuffix("location_country")]}
              </div>
            </div>
            <div className="job-box__details__item">
              <div className="job-box__details__item__title">
                {strings.detail4Title}
              </div>
              <div className="job-box__details__item__value">
                {jobTypes &&
                  uniqueJob &&
                  jobTypes.find(
                    (jobType) => jobType.id === uniqueJob.job_type
                  ) &&
                  jobTypes.find((jobType) => jobType.id === uniqueJob.job_type)[
                    addLanguageSuffix("name")
                  ]}
              </div>
            </div>
          </div>
        </div>
        <div className="job-details">
          <div className="job-details__title">
            {strings.jobDescriptionTitle}
          </div>

          <div className="detail-text">
            {uniqueJob &&
              uniqueJob[addLanguageSuffix("description")] &&
              uniqueJob[addLanguageSuffix("description")]
                //todo check if this works
                .split("\n")
                .map((i) => {
                  return <p>{i}</p>;
                })}
          </div>
          <br></br>
          <div className="bottom-box">
            <div>
              <div className="job-details__title">{strings.weOfferTitle}</div>

              <div className="detail-text">
                {uniqueJob &&
                  uniqueJob[addLanguageSuffix("offering")] &&
                  uniqueJob[addLanguageSuffix("offering")]
                    .split("\\n")
                    .filter((i) => i)
                    .map((i) => {
                      return <p>{i}</p>;
                    })}
              </div>
            </div>
            <div>
              <div className="job-details__title">
                {strings.requirementsTitle}
              </div>

              <div className="detail-text">
                {uniqueJob &&
                  uniqueJob[addLanguageSuffix("asking")] &&
                  uniqueJob[addLanguageSuffix("asking")]
                    .split("\\n")
                    .filter((i) => i)
                    .map((i) => {
                      return <p>{i}</p>;
                    })}
              </div>
            </div>
          </div>
        </div>
        <div className="more_questions">
          <div className="title-box">
            <div className="title-text">{strings.moreQuestionsTitle}</div>
            <div className="subtitle-text">{strings.moreQuestionsSubtitle}</div>
          </div>
          <a href="tel:+39 045 853 1464">
            <Button
              title={strings.callNowBtnText}
              image="/assets/images/icon_telephone.png"
              color="#1EBD53"
            />
          </a>
          <div className="clause">{strings.callNowBtnClause}</div>
        </div>
        <div className="form-img">
          <div className="form-box">
            <Form
              isUnique={true}
              uniqueId={id}
              uniqueTitle={uniqueJob && uniqueJob[addLanguageSuffix("title")]}
              checked={1}
            ></Form>
          </div>
          <div className="carousel_wrapper">
            <Carousel></Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

UniqueJob.propTypes = {};

UniqueJob.defaultProps = {};

export default UniqueJob;
