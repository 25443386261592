import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Contact.scss";

import { Helmet } from "react-helmet-async";

import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";
import Badge from "../Badge/Badge";
import Form from "../Form/Form";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    pageTitle: "Attiva - Contact",
    breadCrumbsText: "Contact",
    title: "Contact Attiva",
    badge1Title: "Our headquarters",
    badge1Text: "9:00- 17:00",
    badge2Title: "Send your CV via E-mail",
    badge2Text: "We will answer you quickly",
    badge3Title: "Call us",
    badge3Text: "To find out more details about our positions",
  },
  ro: {
    pageTitle: "Attiva - Contact",
    breadCrumbsText: "Contact",
    title: "Contact Attiva",
    badge1Title: "Sediul nostru",
    badge1Text: "9:00- 17:00",
    badge2Title: "Trimite CV-ul pe Email",
    badge2Text: "Iți vom răspunde rapid",
    badge3Title: "Sună-ne",
    badge3Text: "Pentru a afla mai multe detalii despre posturile noastre",
  },

  it: {
    pageTitle: "Attiva - Contatto",
    breadCrumbsText: "Contatto",
    title: "Contatta Attiva",
    badge1Title: "La nostra sede",
    badge1Text: "9:00- 17:00",
    badge2Title: "Invia il tuo CV via e-mail",
    badge2Text: "Ti risponderemo a breve",
    badge3Title: "Chiamaci",
    badge3Text: "Per scoprire maggiori dettagli sulle nostre posizioni",
  },
});

const Contact = () => {
  const [local, setLocal] = useState(strings);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="Contact" data-testid="Contact">
      <Helmet>
        <title> {strings.pageTitle} </title>
      </Helmet>

      <BreadCrumbs
        items={[
          {
            text: `${strings.breadCrumbsText}`,
            link: "/contact",
          },
        ]}
      />

      <div className="container main">
        <div className="title-text title-box">{strings.title}</div>
        <div className="badges">
          <Badge
            background=" #D6E9FF8A"
            image="/assets/images/location.png"
            title={strings.badge1Title}
            title_color="#004BA3"
            text={strings.badge1Text}
          >
            <div className="cyan">
              Via Garofoli, 55 - 37057 <br /> San Giovanni Lupatoto (VR), IT
            </div>
          </Badge>
          <Badge
            background=" #D6E9FF8A"
            image="/assets/images/mail.png"
            title={strings.badge2Title}
            title_color="#004BA3"
            text={strings.badge2Text}
          >
            <a href="mailto:info@attivagroup.it">
              <div className="cyan">info@attivagroup.it</div>
            </a>
          </Badge>
          <Badge
            background="#E2F8E9"
            image="/assets/images/phone.png"
            title={strings.badge3Title}
            title_color="#1EBD53"
            text={strings.badge3Text}
          >
            <a href="tel:+39 045 853 1464">
              <div className="cyan">+39 045 853 1464</div>
            </a>
          </Badge>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2802.1883175547728!2d11.039310699999998!3d45.385371899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f5e1278b69047%3A0x9ecb3db6a78c7db0!2sVia%20Federico%20Garofoli%2C%2055%2C%2037057%20San%20Giovanni%20Lupatoto%20VR%2C%20Italia!5e0!3m2!1sro!2sro!4v1669147857429!5m2!1sro!2sro"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Form />
      </div>
    </div>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
