import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Faq.scss";

import { Collapse } from "antd";
import LocalizedStrings from "react-localization";

const { Panel } = Collapse;

let strings = new LocalizedStrings({
  en: {
    questions: [
      "Are you a serious company?",
      "How many people work for Attiva?",
      "To work in Italy, is the Romania-Italy trip insured?",
      "Is accommodation provided?",
      "What kind of accommodation?",
      "How does salary payment work?",
    ],
    answers: [
      "Yes, and the 20 years of experience in the field speak for themselves.",
      "More than 100 people.",
      "Yes, the company provides the round trip Romania-Italy.",
      "Yes, workers are provided accommodation.",
      "Furnished and equipped apartments are available to workers (maximum 2 people per room).",
      "The salary is paid monthly, without delay, on the date established by the contract.",
    ],
  },
  ro: {
    questions: [
      "Sunteți o firmă serioasă?",
      "Câte persoane lucrează pentru Attiva?",
      "Pentru a lucra în Italia este asigurată călătoria România-Italia?",
      "Se oferă cazare?",
      "Ce fel de cazare?",
      "Cum funcționează plata salariului?",
    ],
    answers: [
      "Da, și cei 20 de ani de experiență în domeniu își spun cuvântul.",
      "Mai mult de 100 de persoane.",
      "Da, compania asigură călătoria dus-întors România-Italia.",
      "Da, muncitorilor li se oferă cazare.",
      "Sunt puse la dispoziția muncitorilor apartamente mobilate și utilate (maxim 2 persoane în cameră).",
      "Plata salariului se face lunar, fără întârziere, la data stabilită prin contract.",
    ],
  },
  it: {
    questions: [
      "Siete un'azienda seria?",
      "Quante persone lavorano per Attiva?",
      "Per lavorare in Italia il viaggio Romania-Italia è assicurato?",
      "L'alloggio è fornito?",
      "Che tipo di alloggio?",
      "Come funziona il pagamento dello stipendio?",
    ],
    answers: [
      "Sì, e i 20 anni di esperienza nel settore parlano da soli.",
      "Più di 100 persone.",
      "Sì, la compagnia fornisce il viaggio di andata e ritorno Romania-Italia.",
      "Sì, ai lavoratori viene fornito un alloggio.",
      "A disposizione dei lavoratori appartamenti arredati e attrezzati (massimo 2 persone per camera).",
      "Lo stipendio viene corrisposto mensilmente, senza indugio, alla data stabilita dal contratto.",
    ],
  },
});

const questions = [
  {
    id: 0,
    question: "Sunteți o firmă serioasă?",
    answer: "Da, și cei 20 de ani de experiență în domeniu își spun cuvântul.",
  },
  {
    id: 1,
    question: "Câte persoane lucrează pentru Attiva?",
    answer: "Mai mult de 100 de persoane.",
  },
  {
    id: 2,
    question:
      "Pentru a lucra în Italia este asigurată călătoria România-Italia?",
    answer: "Da, compania asigură călătoria dus-întors România-Italia.",
  },
  {
    id: 3,
    question: "Se oferă cazare?",
    answer: "Da, muncitorilor li se oferă cazare.",
  },
  {
    id: 4,
    question: "Ce fel de cazare?",
    answer:
      "Sunt puse la dispoziția muncitorilor apartamente mobilate și utilate (maxim 2 persoane în cameră).",
  },
  {
    id: 5,
    question: "Cum funcționează plata salariului?",
    answer:
      "Plata salariului se face lunar, fără întârziere, la data stabilită prin contract.",
  },
];

const Faq = () => {
  const [local, setLocal] = useState(strings);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="Faq" data-testid="Faq">
      <Collapse accordion defaultActiveKey={["0"]}>
        {questions.map((question, index) => (
          <Panel header={strings.questions[question.id]} key={index}>
            <p>{strings.answers[question.id]}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
