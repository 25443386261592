import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Home.scss";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import SlideshowWithPagination from "react-slideshow-with-pagination";
import Button from "../Button/Button";

import Testimonial from "./Testimonial/Testimonial";
import Carousel from "./Carousel/Carousel";
import JobCard from "../JobCard/JobCard";
import Form from "../Form/Form";
import Faq from "./Faq/Faq";
import { useEffect } from "react";
import { useRef } from "react";
import { addLanguageSuffix } from "../../helpers/addLanguageSuffix";
import LocalizedStrings from "react-localization";

const API_URL = process.env.REACT_APP_API_URL;

let strings = new LocalizedStrings({
  en: {
    title: "Together to achieve",
    title2: "common goals",
    subtitle: "Are you eager to improve your professional",
    subtitle2: "skills and learn new things?",
    callBtnText: "Call now",
    callBtnClause: "*Telephone rates apply",
    applyNowBtnText: "Apply now",
    badge1Text: "Over 20 years",
    badge1Text2: "of experience",
    badge2Text: "Salaries always",
    badge2Text2: "on time",
    badge3Text: "Accommodation and ",
    badge3Text2: "transport",
    badge4Text: "Colleagues who",
    badge4Text2: "speak Romanian",
    section2Title: "Join our team",
    section2Title2: "of professionals.",
    testimonialsTitle: "Testimonials",
    faqTitle: "FAQ",
    faqSubtitle: "The most frequent questions",
    moreQuestionsTitle: "Still have questions?",
    moreQuestionsSubtitle: "We are here to help you find the right answer",
  },
  ro: {
    title: "Împreună pentru atingerea",
    title2: "obiectivelor comune",
    subtitle: "Sunteți dornic să vă perfecționați abilitățile",
    subtitle2: "profesionale și să învățați lucruri noi?",
    callBtnText: "Sună acum",
    callBtnClause: "*Se aplică tarife telefonice",
    applyNowBtnText: "Aplică acum",
    badge1Text: " Peste 20 de ani",
    badge1Text2: "de experienta",
    badge2Text: "Salarii mereu",
    badge2Text2: "la timp",
    badge3Text: "Cazare si",
    badge3Text2: "transport",
    badge4Text: "Colegi vorbitori",
    badge4Text2: "de limba romana",
    section2Title: "Alăturați-vă echipei",
    section2Title2: "noastre de profesioniști.",
    testimonialsTitle: "Testimoniale",
    faqTitle: "FAQ",
    faqSubtitle: "Cele mai frecvente intrebări",
    moreQuestionsTitle: "Încă mai ai întrebări?",
    moreQuestionsSubtitle:
      "Suntem aici ca să te ajutam să gasești răspunsul potrivit",
  },

  it: {
    title: "Insieme per raggiungere",
    title2: "obiettivi comuni",
    subtitle: "Desideri migliorare le tue capacità",
    subtitle2: "professionali e imparare cose nuove?",
    callBtnText: "Chiama ora",
    callBtnClause: "*Si applicano le tariffe telefoniche",
    applyNowBtnText: "Candidati ora",
    badge1Text: " Oltre 20 anni",
    badge1Text2: "di esperienza",
    badge2Text: "Stipendi sempre ",
    badge2Text2: "puntuali",
    badge3Text: "Alloggio e",
    badge3Text2: "trasporto",
    badge4Text: "Colleghi che",
    badge4Text2: "parlano romeno",
    section2Title: "Unisciti al nostro team",
    section2Title2: "di professionisti.",
    testimonialsTitle: "Testimonianze",
    faqTitle: "FAQ",
    faqSubtitle: "Le domande più frequenti",
    moreQuestionsTitle: "Hai ancora domande?",
    moreQuestionsSubtitle:
      "Siamo qui per aiutarti a trovare la risposta giusta",
  },
});

let testimonials = [
  {
    id: 0,
    name: "Balaci Florentin",
    img: "/assets/images/testimonials/balaci_florentin.png",
  },
  {
    id: 1,
    name: "Dudu Mihăița Valentin",
    img: "/assets/images/testimonials/dudu_valentin.png",
  },
  {
    id: 2,
    name: "Paraschivescu Cristian",
    img: "/assets/images/testimonials/paraschivescu_cristian.png",
  },
  {
    id: 3,
    name: "Cicirma Alexandru",
    img: "/assets/images/testimonials/cicirma_alexandru.png",
  },
  {
    id: 4,
    name: "Stănoiu Daniel",
    img: "/assets/images/testimonials/stanoiu_daniel.png",
  },

  {
    id: 5,
    name: "Simion Viola-Raul",
    img: "/assets/images/testimonials/simion_raul.png",
  },
];

const Home = () => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [jobTypes, setJobTypes] = React.useState([]);
  const [homeJobs, setHomeJobs] = React.useState([]);
  const location = useLocation();
  const [checkedJobType, setCheckedJobType] = React.useState(1);
  const [local, setLocal] = useState(strings);

  const getJobTypes = async () => {
    const response = await fetch(`${API_URL}/job_types`);
    const data = await response.json();
    setJobTypes(data.job_types);
  };

  const getHomeJobs = async () => {
    const response = await fetch(`${API_URL}/home_jobs`);
    const data = await response.json();
    setHomeJobs(data.home_jobs.filter((job) => job.active == true));
  };

  const testimonialHandler = (i) => {
    setCurrentPage(i);
  };

  const myRef = useRef(null);
  const clickViewJob = (id) => {
    setCheckedJobType(id);
    window.scrollTo({
      top: myRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getJobTypes();
    getHomeJobs();

    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  useEffect(() => {}, [checkedJobType]);

  const cutString = (string) => {
    if (string.length > 100) {
      string = string.slice(0, 100) + "...";
    }
    return string;
  };

  return (
    <div className="Home" data-testid="Home">
      <div className="background"></div>
      <Helmet>
        <title> Attiva </title>
      </Helmet>
      <div className="container">
        <div className="section-1">
          <div className="left">
            <div className="title">
              {strings.title} <br></br>
              {strings.title2}
            </div>
            <div className="subtitle">
              {strings.subtitle} <br></br>
              {strings.subtitle2}
            </div>
            <div className="flex_buttons">
              <div>
                <a href="tel:+39 045 853 1464" className="tel_container">
                  <Button
                    title={`${strings.callBtnText}`}
                    image="/assets/images/icon_telephone.png"
                    color="#1EBD53"
                  />
                </a>
                <div className="clause">{strings.callBtnClause}</div>
              </div>
              <div style={{ paddingLeft: "25px" }}></div>
              <Link to={"/jobs"}>
                <Button
                  title={`${strings.applyNowBtnText}`}
                  image="/assets/images/icon_paperclip.png"
                  color="#3F95F9"
                />
              </Link>
            </div>
          </div>
          <div className="right">
            <Carousel></Carousel>
          </div>
        </div>
        <div className="badges">
          <div className="col2">
            <div className="badge">
              <div className="badge-icon">
                <img src="assets/images/badge-1.png"></img>
              </div>
              <div className="badge-text">
                {" "}
                {strings.badge1Text} <br></br> {strings.badge1Text2}{" "}
              </div>
            </div>
            <div className="badge">
              <div className="badge-icon">
                <img src="assets/images/badge-2.png"></img>
              </div>
              <div className="badge-text">
                {" "}
                {strings.badge2Text} <br></br> {strings.badge2Text2}{" "}
              </div>
            </div>
          </div>
          <div className="col2">
            <div className="badge">
              <div className="badge-icon">
                <img src="assets/images/badge-3.png"></img>
              </div>
              <div className="badge-text">
                {" "}
                {strings.badge3Text} <br></br> {strings.badge3Text2}{" "}
              </div>
            </div>
            <div className="badge">
              <div className="badge-icon">
                <img src="assets/images/badge-4.png"></img>
              </div>
              <div className="badge-text">
                {strings.badge4Text} <br></br> {strings.badge4Text2}
              </div>
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="title-box">
            <div className="title">
              {strings.section2Title} <br></br> {strings.section2Title2}
            </div>
            <div className="striped_rect"></div>
          </div>
          {typeof homeJobs !== "undefined" &&
            homeJobs.length > 0 &&
            typeof jobTypes !== "undefined" &&
            jobTypes.length > 0 && (
              <SlideshowWithPagination
                showNumbers={false}
                showDots={true}
                showArrows={false}
                interval={10000}
              >
                {homeJobs
                  .slice(0, Math.ceil(homeJobs.length / 3))
                  .map((x, index) => {
                    var selectedHomeJobs = homeJobs.slice(
                      index * 3,
                      index * 3 + 3
                    );
                    return (
                      <React.Fragment key={index}>
                        <div className="jobs">
                          {selectedHomeJobs.map((job, index) => (
                            <JobCard
                              key={index}
                              specialization={{
                                name: jobTypes.find(
                                  (jobType) => jobType.id === job.job_type
                                )[addLanguageSuffix("name")],
                                color: "#3F95F9",
                                background: "#E6F2FF",
                              }}
                              location={null}
                              title={cutString(job[addLanguageSuffix("title")])}
                              tags={null}
                              jobType={{ id: job.id }}
                              onClick={() => clickViewJob(job.job_type)}
                              jobDisplayType="homeJob"
                              onClickr={() => {
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          ))}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </SlideshowWithPagination>
            )}
        </div>
        <div ref={myRef}></div>
        <Form checked={checkedJobType} />
        <div className="testimonials">
          <div className="title-box title-text">
            {strings.testimonialsTitle}
          </div>

          <SlideshowWithPagination
            showNumbers={false}
            showDots={true}
            showArrows={false}
            interval={10000}
          >
            {testimonials.slice(0, testimonials.length / 3).map((x, index) => {
              var selectedTestimonials = testimonials.slice(
                index * 3,
                index * 3 + 3
              );
              return (
                <React.Fragment key={index}>
                  <div className="testimonials-wrapper">
                    {selectedTestimonials.map((testimonial, index) => (
                      <Testimonial
                        key={index}
                        testimonial={testimonial}
                        // name={testimonial.name}
                        // text={testimonial.text}
                        // img={testimonial.img}
                      />
                    ))}
                  </div>
                </React.Fragment>
              );
            })}
          </SlideshowWithPagination>
          <div className="scrollbar">
            {[...Array(testimonials.length / 3)].map((e, i) => (
              <span
                className={"page " + (currentPage == i ? "selected" : "")}
                onClick={() => testimonialHandler(i)}
                key={i}
              ></span>
            ))}
          </div>
        </div>
        <div className="FAQ">
          <div className="title-box">
            <div className="title-text">{strings.faqTitle}</div>
            <div className="subtitle-text">{strings.faqSubtitle}</div>
          </div>
          <Faq />
          <div className="more_questions">
            <div className="title-box">
              <div className="title-text">{strings.moreQuestionsTitle}</div>
              <div className="subtitle-text">
                {strings.moreQuestionsSubtitle}
              </div>
            </div>
            <a href="tel:+39 045 853 1464">
              <Button
                title={`${strings.callBtnText}`}
                image="/assets/images/icon_telephone.png"
                color="#1EBD53"
              />
            </a>
            <div className="clause">{strings.callBtnClause}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
