export const addLanguageSuffix = (str) => {
  const language = window.localStorage.getItem("lang");
  let suffix = "";

  if (language === "it") {
    suffix = "_it";
  } else if (language === "ro") {
    suffix = "_ro";
  } else if (language === "en") {
    suffix = "_en";
  }

  return `${str}${suffix}`;
};
