import React from "react";
import PropTypes from "prop-types";
import "./PrivacyPolicy.scss";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";

import LocalizedStrings from "react-localization";
import { useState } from "react";
import { useEffect } from "react";
let strings = new LocalizedStrings({
  en: {
    pageTitle: 'Attiva - Privacy Policy',
    breadCrumbsTitle: 'Privacy Policy',
    title: "Privacy Policy",
    text1: "PRIVACY PROTECTION",
    text2: "We protect the privacy and security of visitors to our website.",
    text3: "In this policy you will find information about:",
    bullet1: "How to contact us",
    bullet2: "How your information is used",
    bullet3: "Sharing of your information",
    bullet4: "Commercial communications",
    bullet5: "Viewing of online advertisements",
    bullet6: "Your information and non-European countries",
    bullet7: "Personal information management",
    bullet8: "Your rights",
    bullet9: "Changes to privacy protection",
    bullet10: "Cookies",
    title2: "IDENTIFICATION OF THE DATA CONTROLLER AND CONTACT DETAILS:",
    text4: "The data controller is:",
    text5: "Attiva SRL – Società consortile",
    text6: "Via Garofoli, 55",
    text7: "37057, San Giovanni Lupatoto, Verona",
    text8: "P.IVA 01250730197",
    text9:
      "If you have any questions or suggestions about this communication, you would like us to stop using your personal information, you would like to exercise your rights set out above, or for complaints, please get in touch by sending an e-mail to info@attivagroup.it or by calling us on",
    text10: " 045 547344",
    text11: "Identification of the DPO",
    text12:
      "Attiva SRL is not required to appoint a Data Protection Officer pursuant to article 37 of the GDPR",
    text13: "Legal basis of the processing",
    text14:
      "Your personal data will only be processed if you have given your consent or if necessary for the performance of a contract to which you are party or for compliance with a legal obligation (article 6, paragraph 1 letters a, b, c).",
    text15: "TYPE OF DATA COLLECTED",
    text16:
      "We use your personal information in different ways, depending on the type of information.",
    a1: "a) Name, Surname and contact details",
    a2: "To respond to your requests for information using the contact forms present on the website.",
    a3: "b) Contact history",
    a4: "To provide support and assistance, so that when you contact us, you will receive the best possible assistance and our customer care can access all your previous requests.",
    a5: "c) Browser information",
    a6: "The information you provide us with when browsing our website, including device type and, if you choose to share this information, your location and how you use our website, helps us improve our website.",
    b1: "We anonymise and aggregate personal information (so that you cannot be identified) and use it for purposes such as research, data analysis, improvement of the website and app and development of new products and services. We also not communicate this information to third parties.",
    b2: "PURPOSE OF THE PROCESSING",
    b3: "To provide assistance or any information you have requested:",
    b4: "We use the information you enter in our contact forms so that we can answer any questions you may have, send you informative materials or provide support.",
    b5: "Automatic profiling processes",
    b6: "We collect information about you (anonymously) when you use our services, for example, we trace your behaviour and the content browsing flow through Google Analytics. This information includes the functions used; the links clicked on; the type, size and name of the attached files uploaded to the services; search terms.",
    b7: "We do not use automatic profiling systems to determine your preferences and behaviour",
    b8: "ENTITIES TO WHICH DATA MAY BE TRANSFERRED",
    b9: "We do not sell your personal data to third parties (including your name, address where you live, e-mail address, telephone number, etc.) However, we do have to communicate your data to the following companies to be able to provide you with our services pursuant to this policy.",
    b10: "a) Professional service providers, such as marketing agencies, advertising partners and website hosts that collaborate with us in our business",
    b11: "b) Credit rating agencies, the police force and fraud prevention bodies, to help combat fraudulent activity",
    b12: "If you would like to receive a full list of those entities, please send an e-mail to info@attivagroup.it",
    c1: "DATA TRANSFER TO NON-EU COUNTRIES",
    c2: "We collect the information locally and store it within the European Union.",
    c3: "We transfer, process and store the information outside our country of residence, wherever we or our third party service providers operate to provide the services. We take measures to protect this information each time we transfer it.",
    c4: "STORAGE METHODS AND PERIOD",
    c5: "We store your personal information for the period required to provide the services or (if you contact Customer Services) for the time required to produce exclusively the trend analyses and reports relating to the assistance.",
    c6: "Moreover, if reasonably needed or required to comply with legal or regulatory requirements, solve disputes, prevent fraudulent behaviour and abuse or to apply our terms and conditions, we may store some of your personal information even after the said reasonable period has ended.",
    c7: "DATA SUBJECT’S RIGHTS",
    c8: "You shall have the following rights with regard to your personal information:",
    z: "a) The right to be informed when your information is used",
    z1: "b) The right to access the personal information we hold about you",
    z2: "c) The right to request that inaccurate personal information we hold about you be corrected",
    z3: "d) The right to request that your data be erased or that we stop using or collecting it",
    z4: "e) The right to stop receiving direct commercial communications and to withdraw consent to data processing for other types of unnecessary activities.",
    z5: "f) The right to request that we transfer or send parts of your data to you or another service provider",
    z6: "g) The right to file a complaint with the Italian data protection supervisor",
    z7: "If you would like to exercise your rights, or for complaints or questions, please get in touch.",
    x1: "ERASURE POLICY",
    x2: "You can send a request for erasure of your data to the e-mail info@attivagroup.it and we will delete them within the term of 30 days.",
    x3: "It could take a few days to update all the systems and you might continue receiving communications while we process your request.",
    x4: "AMENDMENTS TO THE POLICY",
    x5: "In the event of substantial amendments to this policy, we shall undertake to draw your attention to them on our website or using other means of contact, such as e-mail, so that you can examine the changes before carrying on using our site.",
    x6: "COOKIES",
    x7: "We use cookies on our website. For more information about the cookies, please visit the dedicated section at this link Cookie Policy.",
  },
  ro: {
    pageTitle: 'Attiva - Politica de confidențialitate',
    breadCrumbsTitle: 'Politica de confidențialitate',
    title: "Politica de confidențialitate",
    text1: "PROTEJAREA CONFIDENȚIALITĂȚII",
    text2:
      "Protejăm confidențialitatea și siguranța vizitatorilor site-ului nostru.",
    text3: "În această politică veți găsi informații despre:",
    bullet1: "Cum să ne contactați",
    bullet2: "Modul în care sunt utilizate informațiile dvs.",
    bullet3: "Partajarea informațiilor dvs.",
    bullet4: "Comunicații comerciale",
    bullet5: "Vizionarea publicităților online",
    bullet6: "Informațiile dvs. și țările non-europene",
    bullet7: "Gestionarea informațiilor personale",
    bullet8: "Drepturile dvs.",
    bullet9: "Modificări ale protecției confidențialității",
    bullet10: "Cookie-uri",
    title2: "IDENTIFICAREA CONTROLLERULUI DE DATE ȘI DETALII DE CONTACT:",
    text4: "Controllerul de date este:",
    text5: "Attiva SRL - Societate consortială",
    text6: "Via Garofoli, 55",
    text7: "37057, San Giovanni Lupatoto, Verona",
    text8: "P.IVA 01250730197",
    text9:
      "Dacă aveți întrebări sau sugestii cu privire la această comunicare, dacă doriți să încetați să utilizăm informațiile dvs. personale, dacă doriți să vă exercitați drepturile menționate mai sus sau dacă aveți plângeri, vă rugăm să ne contactați prin e-mail la info@attivagroup.it sau prin apelarea la numărul nostru de telefon",
    text10: "045 547344",
    text11: "Identificarea DPO",
    text12:
      "Attiva SRL nu este obligată să numească un Ofițer de protecție a datelor conform articolului 37 din GDPR",
    text13: "Baza legală a procesării",
    text14:
      "Informațiile dvs. personale vor fi procesate numai dacă ați acordat consimțământul dvs. sau dacă este necesar pentru îndeplinirea unui contract la care sunteți parte sau pentru îndeplinirea unei obligații legale (articolul 6, paragraful 1 literele a, b, c).",
    text15: "TIPURI DE DATE COLECTATE",
    text16:
      "Utilizăm informațiile dvs. personale în moduri diferite, în funcție de tipul de informații.",
    a1: "a) Nume, prenume și detalii de contact",
    a2: "Pentru a răspunde la cererile dvs. de informații folosind formularele de contact prezente pe site.",
    a3: "b) Istoricul contactelor",
    a4: "Pentru a oferi asistență și sprijin, astfel încât atunci când ne contactați, să primiți cea mai bună asistență posibilă și echipa noastră de suport să aibă acces la toate cererile anterioare.",
    a5: "c) Informații despre browser",
    a6: "Informațiile pe care ni le furnizați atunci când navigați pe site-ul nostru, inclusiv tipul dispozitivului și, dacă alegeți să împărtășiți aceste informații, locația dvs. și modul în care utilizați site-ul nostru, ne ajută să îmbunătățim site-ul nostru.",
    b1: "Anonimizăm și agregăm informațiile personale (astfel încât să nu puteți fi identificat) și le utilizăm în scopuri precum cercetarea, analiza datelor, îmbunătățirea site-ului și a aplicației și dezvoltarea de produse și servicii noi. De asemenea, nu comunicăm aceste informații terțelor părți.",
    b2: "SCOPUL PROCESĂRII",
    b3: "Pentru a oferi asistență sau orice informații ați solicitat:",
    b4: "Utilizăm informațiile pe care le introduceți în formularele noastre de contact pentru a vă putea răspunde la orice întrebări pe care le-ați putea avea, pentru a vă trimite materiale informative sau pentru a vă oferi suport.",
    b5: "Procese de profilare automată",
    b6: "Colectăm informații despre dvs. (anonim) atunci când utilizați serviciile noastre, de exemplu, urmărim comportamentul dvs. și fluxul de navigare prin conținut prin intermediul Google Analytics. Aceste informații includ funcțiile utilizate; legăturile apăsate; tipul, dimensiunea și numele fișierelor atașate încărcate în servicii; termenii de căutare.",
    b7: "Nu utilizăm sisteme de profilare automată pentru a determina preferințele și comportamentul dvs.",
    b8: "ENTITĂȚILE CĂRORA LE POT FI TRANSFERATE DATELE",
    b9: "Nu vindem datele dvs. personale către terți (inclusiv numele dvs., adresa unde locuiți, adresa de e-mail, numărul de telefon, etc.). Cu toate acestea, trebuie să comunicăm datele dvs. către următoarele companii pentru a vă putea oferi serviciile noastre conform acestei politici.",
    b10: "a) Prestatori de servicii profesionale, cum ar fi agențiile de marketing, partenerii publicitari și gazdele site-ului web care colaborează cu noi pentru a vă oferi serviciile noastre.",
    b12: "Dacă doriți să primiți o listă completă a acestor entități, vă rugăm să trimiteți un e-mail la info@attivagroup.it",
    c1: "TRAFER DE DATE ÎN ȚĂRI NON-UE",
    c2: "Colectăm informațiile la nivel local și le stocăm în Uniunea Europeană.",
    c3: "Transferăm, procesăm și stocăm informațiile în afara țării noastre de reședință, oriunde operam noi sau furnizorii noștri de servicii terți pentru a furniza serviciile. Luăm măsuri pentru a proteja aceste informații de fiecare dată când le transferăm.",
    c4: "METODE ȘI PERIOADA DE PĂSTRARE",
    c5: "Păstrăm informațiile dumneavoastră personale pentru perioada necesară pentru furnizarea serviciilor sau (dacă contactați Serviciul Clienți) pentru timpul necesar pentru a produce exclusiv analizele de tendințe și rapoartele referitoare la asistență.",
    c6: "În plus, dacă este necesar în mod rezonabil pentru a respecta cerințele legale sau de reglementare, pentru a rezolva disputele, a preveni comportamentul fraudulos și abuzul sau pentru a aplica termenii și condițiile noastre, este posibil să stocăm unele dintre informațiile dumneavoastră personale chiar și după încheierea perioadei rezonabile menționate. .",
    c7: "DREPTURILE SUBIECTULUI DE DATE",
    c8: "Veți avea următoarele drepturi cu privire la informațiile dumneavoastră personale:",
    z: "a) Dreptul de a fi informat atunci când informațiile dumneavoastră sunt utilizate",
    z1: "b) Dreptul de a accesa informațiile personale pe care le deținem despre dumneavoastră",
    z2: "c) Dreptul de a solicita ca informațiile personale inexacte pe care le deținem despre dumneavoastră să fie corectate",
    z3: "d) Dreptul de a solicita ca datele dumneavoastră să fie șterse sau să încetăm să le utilizăm sau să le colectăm",
    z4: "e) Dreptul de a nu mai primi comunicări comerciale directe și de a retrage consimțământul pentru prelucrarea datelor pentru alte tipuri de activități inutile.",
    z5: "f) Dreptul de a solicita să transferăm sau să trimitem părți din datele dumneavoastră către dumneavoastră sau către alt furnizor de servicii",
    z6: "g) Dreptul de a depune o plângere la supervizorul italian pentru protecția datelor",
    z7: "Dacă doriți să vă exercitați drepturile sau pentru reclamații sau întrebări, vă rugăm să contactați.",
    x1: "POLITICA DE ȘTERGERE",
    x2: "Puteți trimite o cerere de ștergere a datelor dumneavoastră la adresa de e-mail info@attivagroup.it și le vom șterge în termen de 30 de zile.",
    x3: "Ar putea dura câteva zile pentru a actualiza toate sistemele și este posibil să continuați să primiți comunicări în timp ce procesăm solicitarea dvs.",
    x4: "MODIFICARI LA POLITICI",
    x5: "În cazul unor modificări substanțiale ale acestei politici, ne vom angaja să vă atragem atenția asupra acestora pe site-ul nostru web sau folosind alte mijloace de contact, cum ar fi e-mailul, astfel încât să puteți examina modificările înainte de a continua să utilizați site-ul nostru. ",
    x6: "COOKIES",
    x7: "Folosim cookie-uri pe site-ul nostru. Pentru mai multe informații despre cookie-uri, vă rugăm să vizitați secțiunea dedicată la acest link Politica cookie.",
  },
  it: {
    pageTitle: 'Attiva - Informativa sulla privacy',
    breadCrumbsTitle: 'Politica sulla riservatezza',
    title: "Informativa sulla privacy",
    text1: "PROTEZIONE DELLA PRIVACY",
    text2:
      "Proteggiamo la privacy e la sicurezza dei visitatori del nostro sito web.",
    text3: "In questa policy troverai informazioni su:",
    bullet1: "Come contattarci",
    bullet2: "Come viene utilizzate le tue informazioni",
    bullet3: "Condivisione delle tue informazioni",
    bullet4: "Comunicazioni commerciali",
    bullet5: "Visualizzazione di pubblicità online",
    bullet6: "Le tue informazioni e i paesi non europei",
    bullet7: "Gestione delle informazioni personali",
    bullet8: "I tuoi diritti",
    bullet9: "Modifiche alla protezione della privacy",
    bullet10: "Cookie",
    title2: "IDENTIFICAZIONE DEL RESPONSABILE DEI DATI E DATI DI CONTATTO:",
    text4: "Il responsabile dei dati è:",
    text5: "Attiva SRL - Società consortile",
    text6: "Via Garofoli, 55",
    text7: "37057, San Giovanni Lupatoto, Verona",
    text8: "P.IVA 01250730197",
    text9:
      "Se hai domande o suggerimenti riguardo a questa comunicazione, vorresti che smettessimo di utilizzare le tue informazioni personali, vorresti esercitare i tuoi diritti sopra elencati o se hai una lamentela, ti preghiamo di contattarci inviando un'e-mail a info@attivagroup.it o chiamandoci al",
    text10: " 045 547344",
    text11: "Identificazione del DPO",
    text12:
      "Attiva SRL non è tenuta a nominare un Data Protection Officer ai sensi dell'articolo 37 del GDPR",
    text13: "Base giuridica del trattamento",
    text14:
      "I tuoi dati personali saranno trattati solo se hai dato il tuo consenso o se necessario per l'esecuzione di un contratto di cui sei parte o per l'adempimento di un obbligo giuridico (articolo 6, paragrafo 1 lettere a, b, c).",
    text15: "TIPO DI DATI RACCOLTI",
    text16:
      "Utilizziamo le tue informazioni personali in modi diversi, a seconda del tipo di informazione.",
    a1: "a) Nome, Cognome e recapiti",
    a2: "Per rispondere alle sue richieste di informazioni tramite i form di contatto presenti sul sito.",
    a3: "b) Cronologia dei contatti",
    a4: "Per fornire supporto e assistenza, in modo che quando ci contatterai, riceverai la migliore assistenza possibile e il nostro customer care potrà accedere a tutte le tue precedenti richieste.",
    a5: "c) Informazioni sul browser",
    a6: "Le informazioni che ci fornisci durante la navigazione nel nostro sito Web, incluso il tipo di dispositivo e, se scegli di condividere queste informazioni, la tua posizione e il modo in cui utilizzi il nostro sito Web, ci aiutano a migliorare il nostro sito Web.",
    b1: "Anonimizziamo e aggreghiamo le informazioni personali (in modo che tu non possa essere identificato) e le usiamo per scopi quali ricerca, analisi dei dati, miglioramento del sito Web e dell'app e sviluppo di nuovi prodotti e servizi. Inoltre, non comunichiamo queste informazioni a terzi.",
    b2: "FINALITÀ DEL TRATTAMENTO",
    b3: "Per fornire assistenza o qualsiasi informazione tu abbia richiesto:",
    b4: "Usiamo le informazioni che inserisci nei nostri moduli di contatto in modo da poter rispondere a qualsiasi domanda tu possa avere, inviarti materiale informativo o fornire supporto.",
    b5: "Processi automatici di profilazione",
    b6: "Raccogliamo informazioni su di te (in forma anonima) quando utilizzi i nostri servizi, ad esempio, tracciamo il tuo comportamento e il flusso di navigazione dei contenuti attraverso Google Analytics. Queste informazioni includono le funzioni utilizzate; i collegamenti cliccati; il tipo, la dimensione e nome dei file allegati caricati nei servizi; termini di ricerca.",
    b7: "Non utilizziamo sistemi automatici di profilazione per determinare le tue preferenze e il tuo comportamento",
    b8: "SOGGETTI AI QUALI POSSONO ESSERE TRASFERITI I DATI",
    b9: "Non vendiamo i tuoi dati personali a terzi (compreso il tuo nome, indirizzo in cui vivi, indirizzo e-mail, numero di telefono, ecc.) Tuttavia, dobbiamo comunicare i tuoi dati alle seguenti società per poter fornire con i nostri servizi ai sensi di questa politica.",
    b10: "a) Fornitori di servizi professionali, come agenzie di marketing, partner pubblicitari e host di siti Web che collaborano con noi nella nostra attività",
    b11: "b) Agenzie di rating del credito, forze di polizia e organismi di prevenzione delle frodi, per aiutare a combattere le attività fraudolente",
    b12: "Se desideri ricevere un elenco completo di tali entità, invia una mail a info@attivagroup.it",
    c1: "TRASFERIMENTO DEI DATI VERSO PAESI EXTRA UE",
    c2: "Raccogliamo le informazioni localmente e le memorizziamo all'interno dell'Unione Europea.",
    c3: "Trasferiamo, elaboriamo e conserviamo le informazioni al di fuori del nostro paese di residenza, ovunque noi o i nostri fornitori di servizi di terze parti operiamo per fornire i servizi. Adottiamo misure per proteggere queste informazioni ogni volta che le trasferiamo.",
    c4: "“MODALITÀ E PERIODO DI CONSERVAZIONE",
    c5: "Conserviamo i tuoi dati personali per il periodo necessario all'erogazione dei servizi o (se ti rivolgi al Servizio Clienti) per il tempo necessario a produrre esclusivamente le analisi andamentali e i report relativi all'assistenza.",
    c6: "Inoltre, se ragionevolmente necessario o richiesto per ottemperare a requisiti legali o regolamentari, risolvere controversie, prevenire comportamenti fraudolenti e abusi o per applicare i nostri termini e condizioni, potremmo conservare alcune delle tue informazioni personali anche dopo che il suddetto periodo ragionevole è terminato .",
    c7: "DREPTURILE SUBIECTULUI DE DATE",
    c8: "Veți avea următoarele drepturi cu privare la informațiile dumneavoastră personale:",
    z: "a) Dreptul de a fi informat atunci când informațiile dumneavoastră sunt utilizate",
    z1: "b) Dreptul de a accesa informațiile personale pe care le deținem despre dumneavoastră",
    z2: "c) Dreptul de a solicita ca informațiile personale inexacte pe care le deținem despre dumneavoastră să fie corectate",
    z3: "d) Il diritto alla richiesta di dati dumneavoastră să fie șterse sau să încetăm să le utilizăm sau să le colectăm",
    z4: "e) Dreptul de a nu mai primi comunicări comerciale directe și de a retrage consimțământul pentru prelucrarea datelor pentru alte tipuri de activități inutili.",
    z5: "f) Dreptul de a sollecitate să transferăm sau să trimitem părți din datele dumneavoastră către dumneavoastră sau către alt furnizor de servicii",
    z6: "g) Dreptul de a depune o plângere la supervizorul italian pentru protecția datelor",
    z7: "Dacă doriți să vă exercitați drepturile sau pentru reclamații sau întrebări, vă rugăm să contactați.",
    x1: "POLITICA DE ȘTERGERE",
    x2: "Puteți trimite o cerere de ștergere a datalor dumneavoastră l'indirizzo e-mailinfo@attivagroup.it și le vomșterge în termen de 30 de zile.",
    x3: "Ar putea dura câteva zile pentru a actualiza toate sistemele și este posibil să continuați să primiți comunicări în timp ce procesăm sollecitarea dvs.",
    x4: "MODIFICARE LA POLITICA",
    x5: "In caso di modifiche sostanziali agli acesti politici, non vom angaja să vă atragem atenția asupra acestora pe site-ul nostru web sau folosind alter mijloace de contact, cum ar fi e-mailul, astfel încât să puteți examina modificările înainte de a continua să utilizați site-ul nostru. ",
    x6: "COOKIE",
    x7: "Folosim cookie-uri pe site-ul nostru. Pentru mai multe informații despre cookie-uri, vă rugăm să vizitați secțiunea dedicată la acest link Politica cookie.",
  },
});

const PrivacyPolicy = () => {
  const [local, setLocal] = useState(strings);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);
  return (
    <div className="PrivacyPolicy" data-testid="PrivacyPolicy">
      <Helmet>
        <title> {strings.pageTitle} </title>
      </Helmet>

      <BreadCrumbs
        items={[
          {
            text: `${strings.breadCrumbsTitle}`,
            link: "/privacy-policy",
          },
        ]}
      />
      <div className="container">
        <div className="title">{strings.title}</div>
        <div className="subtitle">{strings.text1}</div>
        <div className="text">{strings.text2}</div>
        <div className="text">{strings.text3}</div>
        <br />
        <ul>
          <li>{strings.bullet1}</li>
          <li>{strings.bullet2}</li>
          <li>{strings.bullet3}</li>
          <li>{strings.bullet4}</li>
          <li>{strings.bullet5}</li>
          <li>{strings.bullet6}</li>
          <li>{strings.bullet7}</li>
          <li>{strings.bullet8}</li>
          <li>{strings.bullet9}</li>
        </ul>
        <div className="subtitle">{strings.title2}</div>
        <div className="text">{strings.text4}</div>
        <br />
        <div className="adress">
          <strong>
            <div>{strings.text5}</div>
          </strong>
          <div>{strings.text6}</div>
          <div>{strings.text7}</div>
          <div>{strings.text8}</div>
        </div>
        <br />
        <br />
        <div className="text">{strings.text9 + " " + strings.text10} </div>
        <br />
        <strong>
          <div className="text">{strings.text11}</div>
        </strong>
        <div className="text">{strings.text12}</div>
        <strong>
          <div className="text">{strings.text13}</div>
        </strong>
        <div className="text">{strings.text14}</div>

        <div className="subtitle">{strings.text15}</div>
        <div className="text">{strings.text16}</div>
        <br />
        <strong>
          <div className="text">{strings.a1}</div>
        </strong>
        <div className="text">{strings.a2}</div>
        <strong>
          <div className="text">{strings.a3}</div>
        </strong>
        <div className="text">{strings.a4}</div>
        <strong>
          <div className="text">{strings.a5}</div>
        </strong>
        <div className="text">{strings.a6}</div>
        <br />
        <div className="text">{strings.b1}</div>

        <div className="subtitle">{strings.b2}</div>
        <strong>
          <div className="text">{strings.b3}</div>{" "}
        </strong>
        <div className="text">{strings.b4}</div>
        <br />
        <strong>
          <div className="text">{strings.b5}</div>{" "}
        </strong>
        <div className="text">{strings.b6}</div>
        <div className="text">{strings.b7}</div>

        <div className="subtitle">{strings.b8}</div>
        <div className="text">{strings.b9}</div>
        <br />
        <div className="text">{strings.b10}</div>
        <div className="text">{strings.b11}</div>
        <br />
        <div className="text">{strings.b12}</div>

        <div className="subtitle">{strings.c1}</div>
        <div className="text">{strings.c2}</div>
        <div className="text">{strings.c3}</div>

        <div className="subtitle">{strings.c4}</div>
        <div className="text">{strings.c5}</div>
        <br />
        <div className="text">{strings.c6}</div>

        <div className="subtitle">{strings.c7}</div>
        <div className="text">{strings.c8}</div>
        <br />

        <div className="text">{strings.z1}</div>
        <div className="text">{strings.z2}</div>
        <div className="text">{strings.z3}</div>
        <div className="text">{strings.z4}</div>
        <div className="text">{strings.z5}</div>
        <div className="text">{strings.z6}</div>

        <br />
        <div className="text">{strings.z7}</div>

        <div className="subtitle">{strings.x1}</div>
        <div className="text">{strings.x2}</div>
        <br />
        <div className="text">{strings.x3}</div>

        <div className="subtitle">{strings.x4}</div>
        <div className="text">{strings.x5}</div>
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;
