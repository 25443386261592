import logo from "./logo.svg";
import "./App.scss";
import "antd/dist/antd.css";
import { renderToString } from "react-dom/server";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import JoblistPage from "./pages/JoblistPage";
import AboutPage from "./pages/AboutPage";
import NotFoundPage from "./pages/NotFoundPage";
import UniqueJobPage from "./pages/UniqueJobPage";
import HomeJobPage from "./pages/HomeJobPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

import useWindowDimensions from "./hooks/useWindowDimensions";
import { Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import React from "react";
import { useLocation } from "react-router-dom";

const helmetContext = {};
function App() {
  const lang = localStorage.getItem("lang");
  if (lang == null) {
    localStorage.setItem("lang", "ro");
  }
  return (
    <>
      <div className="App">
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-R0HNKHPRKN"
            ></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-R0HNKHPRKN');
                `}
            </script>
          </Helmet>
          <Navbar />
          <Routes>
            <Route path="" element={<HomePage />}></Route>
            <Route path="/jobs" element={<JoblistPage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/uniqueJob/:id" element={<UniqueJobPage />}></Route>
            <Route path="/homeJob/:id" element={<HomeJobPage />}></Route>
            <Route path="/cookie-policy" element={<CookiePolicyPage />}></Route>
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyPage />}
            ></Route>
            <Route path="/*" element={<NotFoundPage />}></Route>
          </Routes>
          <Footer />
        </HelmetProvider>
      </div>
    </>
  );
}

export default App;
