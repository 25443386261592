import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./JobCard.scss";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    applyNow: "Apply now",
    seeJob: "See the job",
  },
  ro: {
    applyNow: "Aplică acum",
    seeJob: "Vezi jobul",
  },

  it: {
    applyNow: "Candidati ora",
    seeJob: "Guarda l’offerta di lavoro",
  },
});

const JobCard = ({
  jobType: jobType,
  jobDisplayType: jobDisplayType = "uniqueJob",
  specialization: specialization,
  location: location,
  title: title,
  tags: tags,
  onClick: onClick,
  onClickr: onClickr,
}) => {
  const [local, setLocal] = useState(strings);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="JobCard" data-testid="JobCard">
      <div className="wrapper">
        <div className="top">
          <div
            className="specialization"
            style={{
              color: specialization.color ? specialization.color : {},
              background: specialization.background
                ? specialization.background
                : {},
            }}
          >
            {specialization.name}
          </div>
          {location && (
            <div className="location">
              <img
                src="assets/images/location.png"
                className="location-icon"
              ></img>
              {location}
            </div>
          )}
        </div>
        <div className="job-title">{title}</div>
        {tags && (
          <div className="tags">
            {tags.map((tag, index) => {
              return (
                <span key={index} className="tag">
                  {tag}
                </span>
              );
            })}
          </div>
        )}
        <div className="buttons">
          <div className="l" onClick={onClick}>
            {strings.applyNow}
          </div>
          <Link
            to={{
              pathname: "/" + jobDisplayType + "/" + jobType.id,
            }}
          >
            <div onClick={onClickr} className="r">
              {strings.seeJob}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

JobCard.propTypes = {};

JobCard.defaultProps = {};

export default JobCard;
