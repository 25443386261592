import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({ title, color, image }) => (
  <div className="Button" data-testid="Button">
    <div
      className="tel_container"
      style={{
        background: color,
      }}
    >
      <img className="tel_icon" src={image}></img>
      <div className="tel_text">{title}</div>
    </div>
  </div>
);

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
