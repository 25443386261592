import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { FacebookFilled } from "@ant-design/icons";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    menusTitle: "Menu",
    aboutUsMenu: "About us",
    jobsMenu: "Jobs",
    contactMenu: "Contact",
    contactTitle: "Contact",
    infoTitle: "Useful info",
    info1: "Privacy policy",
    info2: "Cookies policy",
    copyright: "ATTIVA s.c.a r.l. All rights reserved",
  },
  ro: {
    menusTitle: "Meniu",
    aboutUsMenu: "Despre noi",
    jobsMenu: "Locuri de munca",
    contactMenu: "Contact",
    contactTitle: "Contact",
    infoTitle: "Info utile",
    info1: "Politica de confidențialitate",
    info2: "Politica de cookies",
    copyright: "ATTIVA s.c.a r.l. Toate drepturile rezervate",
  },

  it: {
    menusTitle: "Menù",
    aboutUsMenu: "Chi siamo",
    jobsMenu: "Lavora con noi",
    contactMenu: "Contatto",
    contactTitle: "Contatto",
    infoTitle: "Informazioni utili",
    info1: "Politica sulla riservatezza",
    info2: "Politica sui cookie",
    copyright: "ATTIVA s.c.a r.l. Tutti i diritti riservati",
  },
});

const Footer = () => {
  const [local, setLocal] = useState(strings);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="Footer" data-testid="Footer">
      <div className="container">
        <div className="wrapper">
          <div className="collumn">
            <Link to={"/"}>
              <img className="logo" src="/assets/images/logo.png"></img>
            </Link>
            <div className="socials">
              <a href="https://www.facebook.com/attivagroup">
                <div className="facebook">
                  <FacebookFilled />
                </div>
              </a>
            </div>
          </div>
          <div className="collumn">
            <div className="title">{strings.menusTitle}</div>
            <div className="text">
              <Link to={"/about"}>
                <div>{strings.aboutUsMenu}</div>
              </Link>
              <Link to={"/jobs"}>
                <div>{strings.jobsMenu}</div>
              </Link>
              <Link to={"/contact"}>
                <div>{strings.contactMenu}</div>
              </Link>
            </div>
          </div>
          <div className="collumn">
            <div className="title">{strings.contactTitle} </div>
            <div className="text">
              <div>Via Garofoli, 55 - 37057</div>
              <div>San Giovanni Lupatoto (VR), IT</div>
              <div>
                <a className="cyan" href="tel:+39 045 853 1464">
                  +39 045 853 1464
                </a>
              </div>
              <div>
                <a className="cyan" href="mailto:info@attivagroup.it">
                  info@attivagroup.it
                </a>
              </div>
            </div>
          </div>
          <div className="collumn">
            <div className="title">{strings.infoTitle}</div>
            <div className="text">
              <Link
                to={{
                  pathname: "/privacy-policy",
                }}
              >
                <div>{strings.info1}</div>
              </Link>
              <Link
                to={{
                  pathname: "/cookie-policy",
                }}
              >
                <div>{strings.info2}</div>
              </Link>
              {/*
              <div>
                Sistem asigurari sociale -{" "}
                <a href="https://www.inps.it/" className="cyan">
                  inps.it
                </a>
              </div>
    */}
            </div>
          </div>
        </div>
        <div className="copyright">
          ©{new Date().getFullYear() + " " + strings.copyright}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
