import React from "react";
import PropTypes from "prop-types";
import "./CookiePolicy.scss";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { Helmet } from "react-helmet-async";

import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";
import { useState } from "react";

import { useEffect } from "react";
let strings = new LocalizedStrings({
  en: {
    pageTitle: 'Attiva - Cookies policy',
    breadCrumbsTitle: 'Cookies policy',
    policy: "Cookie Policy",
    cookies:
      "Cookies are small files that are saved on your device and that store specific settings and data for exchange with our system through your browser. There are 2 types of cookies: so-called session cookies, which are deleted as soon as you close your browser and temporary / permanent cookies, which are saved on your device for a long time or permanently.",
    purpose:
      "This saving allows us to create our websites and our offers according to your needs and makes it easier for you to use the site.",
    technical: "The following table illustrates the technical cookies used:",
    language: "cookies for language recovery;",
    unique: "cookies used by Google Analytics to identify unique visitors;",
    session:
      "cookies used by Google Analytics to identify a session and track the origin of a visitor;",
    activities:
      "cookies used by Google Analytics to store the user’s activities on the pages visited.",
    personal:
      "In the cookies we use, personal data are not saved. Therefore cookies are not attributable to a specific person and therefore not attributable to you. At no time will it be possible to trace your personal data and at no time will you try to do so.",
    accept:
      "In your browser you can set to accept the saving of cookies only on request. If you intend to accept the cookies of this site, but not the cookies of our service companies and our partners, in your browser you can select the setting “block third-party cookies”.",
    menu: "Normally in the menu of your web browser, in the support section, you can see how to reject new cookies and disable existing cookies. In the case of shared computers set up in such a way as to accept cookies and flash cookies, we advise you to always disconnect completely after use.",
    support:
      "For further information and support you can also visit the specific help page of the web browser you are using:",
    reminder:
      "We remind you that, pursuant to Article 7 of Legislative Decree 30 June 2003, n. 196 and later GDPR , there is the right to ask at any time for those who make use of such solutions the cancellation of information collected through cookies.",
  },
  it: {
    pageTitle: 'Attiva - Politica sui cookie',
    breadCrumbsTitle: 'Politica sui cookie',
    policy: "Politica dei cookie",
    cookies:
      "I cookie sono piccoli file che vengono salvati sul tuo dispositivo e che conservano impostazioni e dati specifici per lo scambio con il nostro sistema tramite il tuo browser. Ci sono 2 tipi di cookie: i cosiddetti cookie di sessione, che vengono eliminati non appena chiudi il tuo browser, e i cookie temporanei/permanenti, che vengono salvati sul tuo dispositivo per un lungo periodo o in modo permanente.",
    purpose:
      "Questo salvataggio ci permette di creare i nostri siti web e le nostre offerte secondo le tue esigenze e rende più facile l'utilizzo del sito.",
    technical: "La seguente tabella illustra i cookie tecnici utilizzati:",
    language: "cookie per il recupero della lingua;",
    unique:
      "cookie utilizzati da Google Analytics per identificare i visitatori unici;",
    session:
      "cookie utilizzati da Google Analytics per identificare una sessione e tracciare l'origine di un visitatore;",
    activities:
      "cookie utilizzati da Google Analytics per memorizzare le attività degli utenti nelle pagine visitate.",
    personal:
      "Nelle cookie che utilizziamo, non vengono salvati dati personali. Pertanto, i cookie non sono attribuibili a una persona specifica e quindi non sono attribuibili a te. In nessun momento sarà possibile tracciare i tuoi dati personali e in nessun momento cercheremo di farlo.",
    accept:
      "Nel tuo browser puoi impostare di accettare il salvataggio dei cookie solo su richiesta. Se hai intenzione di accettare i cookie di questo sito, ma non i cookie dei nostri fornitori di servizi e dei nostri partner, nel tuo browser puoi selezionare l'impostazione 'blocca i cookie di terze parti'.",
    menu: "Di solito nel menu del tuo browser web, nella sezione di supporto, puoi vedere come rifiutare i nuovi cookie e disabilitare i cookie esistenti. Nel caso di computer condivisi configurati in modo da accettare cookie e cookie flash, ti consigliamo di disconnetterti completamente dopo ogni utilizzo.",
    support:
      "Per ulteriori informazioni e supporto puoi anche visitare la pagina di aiuto specifica del browser web che stai utilizzando:",
    reminder:
      "Ti ricordiamo che, ai sensi dell'articolo 7 del Decreto Legislativo 30 giugno 2003, n. 196 e successivamente del GDPR, c'è il diritto di chiedere in qualsiasi momento a chi fa uso di tali soluzioni la cancellazione delle informazioni raccolte attraverso i cookie.",
  },
  ro: {
    pageTitle: 'Attiva - Politica de Cookies',
    breadCrumbsTitle: 'Politica de Cookies',
    policy: "Politica de cookie-uri",
    cookies:
      "Cookie-urile sunt fișiere mici care sunt salvate pe dispozitivul dvs. și care stochează setări și date specifice pentru schimbul cu sistemul nostru prin intermediul browserului dvs. Există 2 tipuri de cookie-uri: cookie-urile de sesiune, care sunt șterse imediat după închiderea browserului dvs., și cookie-urile temporare/permanente, care sunt salvate pe dispozitivul dvs. pentru o perioadă lungă de timp sau permanent.",
    purpose:
      "Această salvare ne permite să cream site-urile noastre și ofertele noastre în funcție de nevoile dvs. și vă face mai ușor să utilizați site-ul.",
    technical: "Următoarea tabelă ilustrează cookie-urile tehnice utilizate:",
    language: "cookie-uri pentru recuperarea limbii;",
    unique:
      "cookie-uri utilizate de Google Analytics pentru a identifica vizitatorii unici;",
    session:
      "cookie-uri utilizate de Google Analytics pentru a identifica o sesiune și a urmări originea unui vizitator;",
    activities:
      "cookie-uri utilizate de Google Analytics pentru a stoca activitățile utilizatorilor pe paginile vizitate.",
    personal:
      "În cookie-urile pe care le utilizăm, nu sunt salvate date personale. Prin urmare, cookie-urile nu pot fi atribuite unei persoane specifice și, prin urmare, nu pot fi atribuite dvs. În niciun moment nu va fi posibil să urmărim datele dvs. personale și în niciun moment nu vom încerca să o facem.",
    accept:
      "În browser-ul dvs. puteți seta să acceptați salvarea cookie-urilor numai la solicitare. Dacă intenționați să acceptați cookie-urile acestui site, dar nu cookie-urile companiilor noastre de servicii și partenerilor noștri, în browser-ul dvs. puteți selecta setarea „blocare cookie-uri de la terti”.",
    menu: "În mod normal, în meniul browser-ului dvs. web, în secțiunea de asistență, puteți vedea cum să refuzați cookie-urile noi și să dezactivați cookie-urile existente. În cazul computer-urilor partajate configurate într-un mod care să accepte cookie-uri și cookie-uri flash, vă recomandăm să vă deconectați complet după utilizare.",
    support:
      "Pentru mai multe informații și suport, puteți vizita și pagina de ajutor specifică a browser-ului web pe care îl utilizați:",
    reminder:
      "Vă reamintim că, conform articolului 7 din Decretul Legislativ 30 iunie 2003, nr. 196 și GDPR ulterior, există dreptul de a solicita în orice moment pentru cei care fac utilizarea acestor soluții ștergerea informațiilor colectate prin intermediul cookie-urilor.",
  },
});
const CookiePolicy = () => {
  const [local, setLocal] = useState(strings);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);
  return (
    <div className="CookiePolicy" data-testid="CookiePolicy">
      <Helmet>
        <title> {strings.pageTitle} </title>
      </Helmet>

      <BreadCrumbs
        items={[
          {
            text: `${strings.breadCrumbsTitle}`,
            link: "/cookies-policy",
          },
        ]}
      />
      <div className="container">
        <div className="title">{strings.policy}</div>
        <div className="text">
          <div> {strings.cookies}</div>
          <div> {strings.purpose}</div>
          <br></br>
          <div> {strings.technical}</div>
          <br></br>
          <ul>
            <li> {strings.language}</li>
            <li> {strings.unique}</li>
            <li> {strings.session}</li>
            <li> {strings.activities}</li>
          </ul>
          <div> {strings.personal}</div>
          <br></br>
          <div> {strings.accept}</div>
          <br></br>
          <div> {strings.menu}</div>
          <div> {strings.support}</div>
          <br></br>
          <ul>
            <a href="https://support.google.com/chrome/answer/95647?hl=en">
              <li>Chrome</li>
            </a>
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
              <li>Firefox</li>
            </a>
            <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
              <li>Internet Explorer</li>
            </a>
            <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              <li>Safari</li>
            </a>
          </ul>
          <br></br>
          <div> {strings.reminder}</div>
        </div>
      </div>
    </div>
  );
};

CookiePolicy.propTypes = {};

CookiePolicy.defaultProps = {};

export default CookiePolicy;
