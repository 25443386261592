import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Testimonial.scss";

import { StarFilled } from "@ant-design/icons";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    testimonials: [
      "I have been working with this company for over a year and a half and I haven't encountered any kind of problem, neither from the point of view of the salary nor the job. I recommend with confidence!",
      "I'm from Câmpulung Muscel Argeș, I've been working at Attiva for a year and I'm very satisfied, they're fair with the salary and we have free transport and accommodation provided by the company.",
      "Attiva is a welcoming, caring and serious workplace. They care about the welfare of candidates, are transparent about contracts and payments and are available in case of salary concerns.",
      "I have been working at Attiva since May 2022 and I have had no problems with them financially, the work equipment is ok and the work environment ideal!.",
      "I have an extremely positive impression of this company. I am very happy with how they behave in terms of being fair with money!",
      "The most important thing to say is: very kind and attentive staff, available to clarify any need and punctuality of payments.",
    ],
  },
  ro: {
    testimonials: [
      "Colaborez cu această firmă de peste un an și jumate și nu am întâmpinat nici un fel de problemă, nici din punctul de vedere al salariului și nici al locului de muncă. Recomand cu încredere!",
      "Sunt din Câmpulung Muscel Argeș, lucrez la Attiva un an de zile și sunt foarte mulțumit, sunt corecți cu salariul și avem asigurat transportul și cazarea gratuit din partea firmei.",
      "Attiva este un loc de muncă primitor, îngrijit și serios. Se preocupă de binele candidaților, sunt transparenți cu privire la contracte și plăți și sunt disponibili în caz de nelămuriri la salariu.",
      "Lucrez la Attiva din mai 2022 și nu am avut probleme cu ei din punct de vedere financiar, echipamentele de lucru sunt ok și mediul de lucru ideal!.",
      "Am o impresie extrem de pozitivă despre această firmă. Sunt foarte mulțumit de cum se comportă în ceea ce privește corectitudinea cu banii!",
      "Cel mai important lucru de spus este: personal foarte amabil și atent, disponibil să clarifice orice nevoie și punctualitate la plăți.",
    ],
  },

  it: {
    testimonials: [
      "Lavoro con quest’azienda da più di un anno e mezzo e non ho riscontrato alcun tipo di problema, né dal punto di vista retributivo, né sul posto di lavorvo. Consiglio con fiducia!",
      "Sono di Câmpulung Muscel Argeș, lavoro con Attiva da un anno e sono molto soddisfatto, sono onesti con lo stipendio e abbiamo trasporto e alloggio gratuiti forniti dall'azienda.",
      "Attiva è un luogo di lavoro accogliente, curato e serio. Si preoccupano del benessere dei candidati, sono trasparenti su contratti e pagamenti e sono disponibili in caso di problemi di stipendio.",
      "Lavoro in Attiva da maggio 2022 e non ho avuto problemi economici con loro, l'attrezzatura di lavoro è ok e l'ambiente di lavoro ideale!.",
      "Ho un'impressione estremamente positiva di quest’azienda. Sono molto contento di come si comportano in termini di correttezza con i soldi!",
      "La cosa più importante da dire è: personale gentilissimo e attento, disponibile a chiarire qualsiasi esigenza e puntualità nei pagamenti.",
    ],
  },
});

const Testimonial = ({ testimonial }) => {
  const [local, setLocal] = useState(strings);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="Testimonial" data-testid="Testimonial">
      <div className="wrapper">
        <div className="text">{strings.testimonials[testimonial.id]}</div>
        <div className="image">
          <img src={testimonial.img} />
        </div>
        <div className="name">{testimonial.name}</div>
        <div className="stars">
          <StarFilled />
          <StarFilled />
          <StarFilled />
          <StarFilled />
          <StarFilled />
        </div>
      </div>
    </div>
  );
};

Testimonial.propTypes = {};

Testimonial.defaultProps = {};

export default Testimonial;
