import React from "react";
import PropTypes from "prop-types";
import "./Badge.scss";

const Badge = ({ background, image, title, title_color, text, children }) => (
  <div className="Badge" data-testid="Badge" style={{ background: background }}>
    <div className="wrapper">
      <div className="image">
        <img src={image}></img>
      </div>
      <div className="title" style={{ color: title_color }}>
        {title}
      </div>
      <div className="text-wrapper">
        <div className="text">{text}</div>
      </div>
      <div className="children">{children}</div>
    </div>
  </div>
);

Badge.propTypes = {};

Badge.defaultProps = {};

export default Badge;
