import PropTypes from "prop-types";
import "./Form.scss";
import { UploadOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  message,
} from "antd";
import { useEffect } from "react";
import { addLanguageSuffix } from "../../helpers/addLanguageSuffix";
import LocalizedStrings from "react-localization";
import { Link } from "react-router-dom";

import { useCallback } from "react";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const API_URL = process.env.REACT_APP_API_URL;
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

let strings = new LocalizedStrings({
  en: {
    title: "Fill out the form",
    subtitle: "If your profile matches our requirements,",
    subtitle2: "you will be called for an interview.",

    pickJobLabel: "Choose the job you are applying for",
    pickJobRequiredError: "Choose the job you are applying for!",

    nameInputLabel: "Name and surname",
    nameInputPlaceholder: "John Doe",
    nameInputRequiredError: "Enter a name!",

    emailInputLabel: "E-mail",
    emailInputPlaceholder: "name@email.com",
    emailInputRequiredError: "Enter an e-mail address!",
    emailInputInvalidError: "Enter a valid e-mail address!",

    phoneNumberInputLabel: "Phone",
    phoneNumberInputPlaceholder: "+40 7xxxxxxxx",
    phoneNumberInputRequiredError: "Enter a phone number!",
    phoneNumberInputInvalidError: "Please enter a valid phone number!",

    descriptionInputLabel: "Short description",
    descriptionInputPlaceholder: "About you",
    descriptionInputRequiredError: "Enter a description!",

    uploadCvInputLabel: "Upload CV",
    uploadCvInputPlaceholder: "Upload your CV here, maximum 10 MB",
    uploadCvInputRequiredError: "Enter a CV!",
    uploadCvInputSizeError: "The file is too large, the maximum size is",
    uploadCvInputMultipleFilesError: "You can upload a maximum of one file",

    acceptTermsInputLabel: "I agree with ",
    acceptTermsInputLabel2: "processing of personal data",
    acceptTermsInputRequiredError: "Accept the terms and conditions!",

    submitFormText: "Apply now",
    submitFormSuccessText:
      "The form has been submitted successfully! You will be contacted shortly.",
    submitFormErrorText: "An error occurred...",
    submitFormErrorText2: "...Please try again later.",
  },
  ro: {
    title: "Completați formularul",
    subtitle: "Dacă profilul dvs. se potrivește cerintelor",
    subtitle2: "noastre, veți fi chemat pentru un interviu.",
    pickJobLabel: "Alege jobul pentru care aplici",
    pickJobRequiredError: "Alege jobul pentru care aplici!",
    nameInputLabel: "Nume și prenume",
    nameInputPlaceholder: "Popescu Ion",
    nameInputRequiredError: "Introduceți un nume!",

    emailInputLabel: "Email",
    emailInputPlaceholder: "nume@email.com",
    emailInputRequiredError: "Introduceți o adresă de email!",
    emailInputInvalidError: "Introduceți o adresă de email validă!",
    phoneNumberInputLabel: "Telefon",
    phoneNumberInputPlaceholder: "+40 7xxxxxxxx",
    phoneNumberInputRequiredError: "Introduceți un număr de telefon!",
    phoneNumberInputInvalidError: "Introduceți un număr de telefon valid!",

    descriptionInputLabel: "Scurtă descriere",
    descriptionInputPlaceholder: "Despre tine",
    descriptionInputRequiredError: "Introduceți o descriere!",
    uploadCvInputLabel: "Încarcă CV",
    uploadCvInputPlaceholder: "Incarca aici CV maxim. 10 mb",
    uploadCvInputRequiredError: "Introduceți un CV!",
    uploadCvInputSizeError: "Fișierul este prea mare, mărimea maximă este",
    uploadCvInputMultipleFilesError: "Puteți încărca maxim un fișier",
    acceptTermsInputLabel: "Sunt de acord cu ",
    acceptTermsInputLabel2: "prelucrarea datelor cu caracter personal",
    acceptTermsInputRequiredError: "Acceptați termenii și condițiile!",
    submitFormText: "Aplică acum",
    submitFormSuccessText:
      "Formularul a fost trimis cu succes! Vei fi contactat în cel mai scurt timp.",
    submitFormErrorText: "A apărut o eroare...",
    submitFormErrorText2: "...Vă rugăm să încercați din nou mai târziu.",
  },

  it: {
    title: "Compilare il modulo",
    subtitle: "Se il tuo profilo corrisponde ai nostri requisiti",
    subtitle2: ", verrai contattato per un colloquio.",

    pickJobLabel: "Scegli il lavoro per cui ti stai candidando",
    pickJobRequiredError: "Scegli il lavoro per cui ti stai candidando!",

    nameInputLabel: "Nome e cognome",
    nameInputPlaceholder: "Mario Rossi",
    nameInputRequiredError: "Inserisci un nome!",

    emailInputLabel: "Email",
    emailInputPlaceholder: "nome@email.com",
    emailInputRequiredError: "Inserisci un indirizzo email!",
    emailInputInvalidError: "Inserire un indirizzo email valido!",

    phoneNumberInputLabel: "Telefono",
    phoneNumberInputPlaceholder: "+40 7xxxxxxxx",
    phoneNumberInputRequiredError: "Inserisci un numero telefonico!",
    phoneNumberInputInvalidError:
      "Si prega di inserire un numero di telefono valido!",

    descriptionInputLabel: "Breve descrizione",
    descriptionInputPlaceholder: "Su di te",
    descriptionInputRequiredError: "Inserisci una descrizione!",

    uploadCvInputLabel: "Carica CV",
    uploadCvInputPlaceholder: "Carica qui il tuo CV, massimo 10 MB",
    uploadCvInputRequiredError: "Inserisci un curriculum!",
    uploadCvInputSizeError: "Il file è troppo grande, la dimensione massima è",
    uploadCvInputMultipleFilesError: "Puoi caricare al massimo un file",

    acceptTermsInputLabel: "Sono d'accordo con ",
    acceptTermsInputLabel2: "trattamento dei dati personali",
    acceptTermsInputRequiredError: "Accetta i termini e le condizioni!",

    submitFormText: "Candidati ora",
    submitFormSuccessText:
      "Il modulo è stato inviato con successo! Verrai ricontattato a breve.",
    submitFormErrorText: "Si è verificato un errore...",
    submitFormErrorText2: "...Per favore riprova più tardi.",
  },
});

let reCaptchaKey = "6Lc_VuQjAAAAADFEXr_QBHCVEZ5jBkiWunxRGe6V";

const FormComp = ({
  isUnique = false,
  uniqueId = null,
  uniqueTitle = "",
  checked = 1,
}) => {
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [local, setLocal] = useState(strings);
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [jobTypes, setJobTypes] = useState([]);
  const getJobTypes = async () => {
    const response = await fetch(`${API_URL}/job_types`);
    const data = await response.json();
    setJobTypes(data.job_types);
  };

  const onVerify = useCallback((token) => {
    setToken(token);
  });

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
    getJobTypes();
  }, []);

  useEffect(() => {}, [checked]);

  useEffect(() => {}, [fileList]);

  const handleSubmit = (values) => {
    /*

    if (fileList.length < 1) {
      setError(`${strings.uploadCvInputRequiredError}`);
      return;
    }
    */
    // console.log('reCaptcha token : ', token);

    if (terms == false) {
      setError(`${strings.acceptTermsInputRequiredError}`);
      return;
    }
    console.log(values);
    delete values.accept_terms;

    setLoading(true);

    console.log(fileList.length > 0);
    if (fileList.length < 1) {
      fetch(`${API_URL}/forms`, {
        method: "POST",
        body: JSON.stringify({
          ...values,
          cv: "",
          cv_filename: "",
          token: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setLoading(false);
          message.info(`${strings.submitFormSuccessText}`, 10);
          form.resetFields();
          setFileList([]);
          setError(null);
          setRefreshReCaptcha((r) => !r);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          message.error(
            `${strings.submitFormErrorText}${error.message}${strings.submitFormErrorText2}`
          );
        });
      return;
    }

    const file = fileList[0].originFileObj;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const bytesString = reader.result.split(",")[1];

      // Send a POST request to the API with the form data
      fetch(`${API_URL}/forms`, {
        method: "POST",
        body: JSON.stringify({
          ...values,
          cv: bytesString,
          cv_filename: file.name,
          token: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setLoading(false);
          message.info(`${strings.submitFormSuccessText}`, 10);
          form.resetFields();
          setFileList([]);
          setError(null);
          setRefreshReCaptcha((r) => !r);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          message.error(
            `${strings.submitFormErrorText}${error.message}${strings.submitFormErrorText2}`
          );
        });
    };
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (file) => {
    var isValid = file.size <= MAX_FILE_SIZE;
    if (!isValid) {
      message.error(
        `${strings.uploadCvInputSizeError} ${MAX_FILE_SIZE / 1024 / 1024}MB`
      );
    }

    isValid = isValid && fileList.length < 1;
    if (fileList.length >= 1) {
      message.error(`${strings.uploadCvInputMultipleFilesError}`);
    }
    return isValid || Upload.LIST_IGNORE;
  };

  return (
    <div className="Form" data-testid="Form">
      <div className="title_box">
        <div className="title title-text">{strings.title}</div>
        <div className="subtitle subtitle-text">
          {strings.subtitle} <br></br> {strings.subtitle2}
        </div>
      </div>

      <div className="form_wrapper">
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
        >
          {isUnique == false ? (
            <Form.Item
              label={strings.pickJobLabel}
              name={"job_type"}
              rules={[
                {
                  required: true,
                  message: `${strings.pickJobRequiredError}`,
                },
              ]}
            >
              {typeof jobTypes !== "undefined" && jobTypes.length > 0 && (
                <Radio.Group>
                  {jobTypes.map((job) => {
                    return (
                      <Radio key={job.id} value={job.id}>
                        {job[addLanguageSuffix("name")]}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              )}
            </Form.Item>
          ) : (
            <Form.Item
              label={strings.pickJobLabel}
              name={"unique_job"}
              initialValue={uniqueId}
              rules={[
                {
                  required: true,
                  message: `${strings.pickJobRequiredError}`,
                },
              ]}
            >
              {typeof jobTypes !== "undefined" && jobTypes.length > 0 && (
                <Radio.Group defaultValue={uniqueId}>
                  <Radio key={1} value={uniqueId}>
                    {uniqueTitle}
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: `${strings.nameInputRequiredError}`,
              },
            ]}
            label={strings.nameInputLabel}
            name={"name"}
          >
            <Input placeholder={strings.nameInputPlaceholder} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: `${strings.emailInputRequiredError}`,
              },
              {
                type: "email",
                message: `${strings.emailInputInvalidError}`,
              },
            ]}
            label={strings.emailInputLabel}
            name={"email"}
          >
            <Input placeholder={strings.emailInputPlaceholder} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: `${strings.phoneNumberInputRequiredError}`,
              },
              {
                pattern: new RegExp(/^\+?[0-9 \b]+$/),
                message: `${strings.phoneNumberInputInvalidError}`,
              },
            ]}
            label={strings.phoneNumberInputLabel}
            name={"phone"}
          >
            <Input placeholder={strings.phoneNumberInputPlaceholder} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: `${strings.descriptionInputRequiredError}`,
              },
            ]}
            label={strings.descriptionInputLabel}
            name={"description"}
          >
            <TextArea
              placeholder={strings.descriptionInputPlaceholder}
              rows={4}
            />
          </Form.Item>
          <Form.Item
            name="cv"
            /*
            rules={[
              {
                required: true,
                message: `${strings.uploadCvInputRequiredError}`,
              },
            ]}
            */

            label={strings.uploadCvInputLabel}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              customRequest={dummyRequest}
              accept=".pdf, .doc, .docx"
              multiple={false}
              beforeUpload={handleUpload}
              filelist={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
            >
              <Button>
                <UploadOutlined />
                <span className="upload_text">
                  {strings.uploadCvInputPlaceholder}
                </span>
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="accept_terms"
            rules={[
              {
                required: true,
                message: `${strings.acceptTermsInputRequiredError}`,
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => setTerms(e.target.checked)}>
              {strings.acceptTermsInputLabel}
              <Link to="/privacy-policy">
                <span style={{ color: "#3F95F9" }}>
                  {strings.acceptTermsInputLabel2}
                </span>
              </Link>
            </Checkbox>
          </Form.Item>
          <div className="form-error">{error && <span>{error}</span>}</div>
          <Form.Item>
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </GoogleReCaptchaProvider>

            {/* <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            /> */}
            <Button
              type="primary"
              htmlType="submit"
              className="submit"
              loading={loading}
            >
              {strings.submitFormText}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default FormComp;
