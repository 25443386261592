import React from "react";
import PropTypes from "prop-types";
import "./NotFound.scss";

const NotFound = () => (
  <div className="NotFound" data-testid="NotFound">
    <div className="container">
      <div className="not-found">
        <div className="not-found__title">404</div>
        <div className="not-found__text">Page not found</div>
      </div>
    </div>
  </div>
);

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
