import React from "react";
import PropTypes from "prop-types";
import "./Breadcrumbs.scss";
import { Link } from "react-router-dom";
import { HomeOutlined, RightOutlined } from "@ant-design/icons";

const Breadcrumbs = (items) => (
  <div className="Breadcrumbs" data-testid="Breadcrumbs">
    <div className="container">
      <div className="wrapper">
        <Link to="/">
          <div className="home">
            <HomeOutlined />
          </div>
        </Link>
        {items.items.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="arrow">
                <RightOutlined />
              </div>
              <Link to={item.link}>
                <div className="text_badge">
                  <div className="text">{item.text}</div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

Breadcrumbs.propTypes = {};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
