import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./About.scss";

import { Helmet } from "react-helmet-async";

import Form from "../Form/Form";
import Badge from "../Badge/Badge";
import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    pageTitle: "Attiva - About us",
    breadCrumbsText: "About us",
    section1Text: "We work with people who want",
    section1Text2: "to improve their skills",
    section1Mark: "Together to achieve common goals!",
    section2Item1Title: "Our vision",
    section2Item1Subtitle:
      'We are true to the philosophy of always offering the "highest quality" of every step of our work. What sets us apart is our dedication to work.',
    section2Item1ListItem1:
      "We believe in the quality of professional training",
    section2Item1ListItem2: "We support our team",
    section2Item1ListItem3: "Trust and transparency",
    section2Item2Title: "Over 20 years of experience",
    section2Item2Subtitle:
      "Thanks to the experience gained in more than 20 years of field work and the constant desire to improve our offer, we can offer our customers solid know-how in all areas of competence.",
    section2Item3Title: "We respect the working conditions of our team",
    section2Item3ListItem1: "Protective equipment",
    section2Item3ListItem2: "Contracts, insurances",
    section2Item3ListItem3: "Salaries always on time",
    badge1Title: "Send your CV via E-mail",
    badge1Text: "We will answer you quickly",
    badge2Title: "Call us",
    badge2Text: "To find out more details about our positions",
  },
  ro: {
    pageTitle: "Attiva - Despre noi",
    breadCrumbsText: "Despre noi",
    section1Text: "Lucram cu personal calificat,",
    section1Text2: "si suntem mereu in cautare de personal",
    section1Mark: "Împreună pentru atingerea obiectivelor comune!",
    section2Item1Title: "Viziunea noastră",
    section2Item1Subtitle:
      "Suntem fideli filosofiei care oferă întotdeauna „cea mai înaltă calitate“ al fiecărei etape a muncii noastre. Ne diferențiază dedicarea în muncă.",
    section2Item1ListItem1: "Credem în calitatea formării profesionale",
    section2Item1ListItem2: "Ne susținem echipa",
    section2Item1ListItem3: "Încredere și transparență",
    section2Item2Title: "Peste 20 ani experiență",
    section2Item2Subtitle:
      "Datorită experienței dobândite în cei peste 20 de ani de muncă de teren și a dorinței constante de a ne perfecționa oferta, putem oferi clienților noștri un know-how solid în toate domeniile de competență.",
    section2Item3Title: "Respectăm condițiile de muncă ale echipei noastre",
    section2Item3ListItem1: "Echipamente de protectie",
    section2Item3ListItem2: "Contracte, asigurări",
    section2Item3ListItem3: "Salarii mereu la timp",
    badge1Title: "Trimite CV-ul pe Email",
    badge1Text: "Iți vom răspunde rapid",
    badge2Title: "Sună-ne",
    badge2Text: "Pentru a afla mai multe detalii despre posturile noastre",
  },

  it: {
    pageTitle: "Attiva - Chi siamo",
    breadCrumbsText: "Chi siamo",
    section1Text: "Lavoriamo con personale qualificato",
    section1Text2: "E siamo sempre alla ricerca di nuove figure",
    section1Mark: "Insieme per raggiungere obiettivi comuni!",
    section2Item1Title: "La nostra visione",
    section2Item1Subtitle:
      'Siamo fedeli alla filosofia di offrire sempre "la massima qualità" in ogni fase del nostro lavoro. Ciò che ci distingue è la nostra dedizione al lavoro.',
    section2Item1ListItem1:
      "Crediamo nella qualità della formazione professionale",
    section2Item1ListItem2: "Sosteniamo il nostro team",
    section2Item1ListItem3: "Fiducia e trasparenza",
    section2Item2Title: "Oltre 20 anni di esperienza",
    section2Item2Subtitle:
      "Grazie all'esperienza maturata in oltre 20 anni di lavoro sul campo e alla costante volontà di migliorare la nostra offerta, possiamo offrire ai nostri clienti un solido know-how in tutti i settori di competenza.",
    section2Item3Title: "Rispettiamo le condizioni di lavoro del nostro team",
    section2Item3ListItem1: "Dispositivi di protezione",
    section2Item3ListItem2: "Contratti, assicurazioni",
    section2Item3ListItem3: "Stipendi - sempre puntuali",
    badge1Title: "Invia il tuo CV via e-mail",
    badge1Text: "Ti risponderemo a breve",
    badge2Title: "Chiamaci",
    badge2Text: "Per scoprire maggiori dettagli sulle nostre posizioni",
  },
});

const About = () => {
  const [local, setLocal] = useState(strings);
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="About" data-testid="About">
      <div className="background">
        <Helmet>
          <title> {strings.pageTitle} </title>
        </Helmet>

        <BreadCrumbs
          items={[
            {
              text: strings.breadCrumbsText,
              link: "/about",
            },
          ]}
        />
      </div>
      <div className="container main">
        <div className="section-1">
          <div className="wrapper">
            <div className="title-text">
              {strings.section1Text} <br /> {strings.section1Text2}
            </div>
            <div className="image">
              <img
                className="block"
                src="/assets/images/deco_arrow_about.svg"
              ></img>
              <img className="block" src="/assets/images/about.png"></img>

              <div className="striped_rect"></div>
            </div>
          </div>
          <div className="mark">
            <div className="mark-wrapper">
              <div className="mark-icon">
                <img src="/assets/images/about_icons/mark.png"></img>
              </div>
              <div className="mark-text">{strings.section1Mark}</div>
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="item">
            <div className="left">
              <div className="item-icon">
                <img src="/assets/images/about_icons/1.png"></img>
              </div>
              <div className="item-title">{strings.section2Item1Title}</div>
              <div className="item-text">
                <p>{strings.section2Item1Subtitle}</p>
                <p style={{ marginTop: "14px" }}>
                  <span>·</span>
                  {strings.section2Item1ListItem1}
                </p>
                <p>
                  <span>·</span>
                  {strings.section2Item1ListItem2}
                </p>
                <p>
                  <span>·</span>
                  {strings.section2Item1ListItem3}
                </p>
              </div>
            </div>
            <div className="right">
              <img src="/assets/images/about_icons/1-1.png"></img>
            </div>
          </div>
          <div className="item">
            <div className="right">
              <img src="/assets/images/about_icons/2-2.png"></img>
            </div>
            <div className="left">
              <div className="item-icon">
                <img src="/assets/images/about_icons/2.png"></img>
              </div>
              <div className="item-title">{strings.section2Item2Title}</div>
              <div className="item-text">
                <p>{strings.section2Item2Subtitle}</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="left">
              <div className="item-icon">
                <img src="/assets/images/about_icons/3.png"></img>
              </div>
              <div className="item-title">{strings.section2Item3Title}</div>
              <div className="item-text">
                <p style={{ marginTop: "14px" }}>
                  <span>·</span>
                  {strings.section2Item3ListItem1}
                </p>
                <p>
                  <span>·</span>
                  {strings.section2Item3ListItem2}
                </p>
                <p>
                  <span>·</span>
                  {strings.section2Item3ListItem3}
                </p>
              </div>
            </div>
            <div className="right">
              <img src="/assets/images/about_icons/3-3.png"></img>
            </div>
          </div>
        </div>
        <div className="badges">
          <Badge
            background=" #D6E9FF8A"
            image="/assets/images/mail.png"
            title={strings.badge1Title}
            title_color="#004BA3"
            text={strings.badge1Text}
          >
            <a href="mailto:info@attivagroup.it">
              <div className="cyan">info@attivagroup.it</div>
            </a>
          </Badge>
          <Badge
            background="#E2F8E9"
            image="/assets/images/phone.png"
            title={strings.badge2Title}
            title_color="#1EBD53"
            text={strings.badge2Text}
          >
            <a href="tel:+39 045 853 1464">
              <div className="cyan">+39 045 853 1464</div>
            </a>
          </Badge>
        </div>
        <Form />
      </div>
    </div>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default About;
