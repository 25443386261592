import React from "react";
import PropTypes from "prop-types";
import "./Joblist.scss";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Form from "../Form/Form";
import Badge from "../Badge/Badge";
import BreadCrumbs from "../Breadcrumbs/Breadcrumbs";
import { Button, Radio, Space, Divider } from "antd";

import { Pagination } from "antd";
import { useEffect } from "react";

import JobCard from "../JobCard/JobCard";
import { addLanguageSuffix } from "../../helpers/addLanguageSuffix";
import LocalizedStrings from "react-localization";

const API_URL = process.env.REACT_APP_API_URL;

let strings = new LocalizedStrings({
  en: {
    pageTitle: "Attiva - Jobs",
    breadCrumbsText: "Jobs",
    section1Title: "We are looking for professionals",
    section1Subtitle: "Are you eager to improve your professional",
    section1Subtitle2: "skills and learn new things?",
    badge1Title: "Send your CV via E-mail",
    badge1Text: "We will answer you quickly",
    badge2Title: "Call us",
    badge2Text: "To find out more details about our positions",
    jobListTitle: "Vacancies",
    jobListTypeAll: "All",
    jobListNoJob: "There are no positions available",
  },
  ro: {
    pageTitle: "Attiva - Locuri de muncă",
    breadCrumbsText: "Locuri de muncă",
    section1Title: "Căutăm profesioniști",
    section1Subtitle: "Sunteți dornic să vă perfecționați abilitățile",
    section1Subtitle2: "profesionale și să învățați lucruri noi?",
    badge1Title: "Trimite CV-ul pe Email",
    badge1Text: "Iți vom răspunde rapid",
    badge2Title: "Sună-ne",
    badge2Text: "Pentru a afla mai multe detalii despre posturile noastre",
    jobListTitle: "Posturi libere",
    jobListTypeAll: "Toate",
    jobListNoJob: "Nu există posturi disponibile",
  },

  it: {
    pageTitle: "Attiva –lavoro",
    breadCrumbsText: " Offerte di lavoro",
    section1Title: "Cerchiamo professionisti",
    section1Subtitle: "Desideri migliorare le tue capacità",
    section1Subtitle2: "professionali e imparare cose nuove?",
    badge1Title: "Invia il tuo CV via e-mail",
    badge1Text: "Ti risponderemo a breve",
    badge2Title: "Chiamaci",
    badge2Text: "Per scoprire maggiori dettagli sulle nostre offerte di lavoro",
    jobListTitle: "Posti vacanti",
    jobListTypeAll: "Tutti",
    jobListNoJob: "Non ci sono posizioni disponibili",
  },
});

const Joblist = () => {
  const [local, setLocal] = useState(strings);
  const [selected, setSelected] = useState("all");
  const [jobTypes, setJobTypes] = useState([]);
  const [uniqueJobs, setUniqueJobs] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(4);

  const [filteredJobs, setFilteredJobs] = useState([]);
  const [paginatedJobs, setPaginatedJobs] = useState([]);

  const getJobTypes = async () => {
    const response = await fetch(`${API_URL}/job_types`);
    const data = await response.json();
    setJobTypes(data.job_types);
  };

  const getUniqueJobs = async () => {
    const response = await fetch(`${API_URL}/unique_jobs`);
    const data = await response.json();
    const unique_jobs = data.unique_jobs.filter((x) => x.active == true);
    setUniqueJobs(unique_jobs);
    setFilteredJobs(unique_jobs);
    setPaginatedJobs(unique_jobs);
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
    getJobTypes();
    getUniqueJobs();
  }, []);

  const handleFilter = (id) => {
    if (id == 0) {
      setFilteredJobs(uniqueJobs);
      setPaginatedJobs(uniqueJobs);

      return;
    }
    var filteredJobs = uniqueJobs.filter((x) => x.job_type == id);
    setFilteredJobs(filteredJobs);
    setPaginatedJobs(filteredJobs);
  };

  const cutString = (string) => {
    if (string.length > 100) {
      string = string.slice(0, 100) + "...";
    }
    return string;
  };
  useEffect(() => {
    console.log(selected);
    setCurrentPage(1);
    handleFilter(selected);
  }, [selected]);

  const handlePagination = () => {
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
    setPaginatedJobs(currentJobs);
  };

  useEffect(() => {
    handlePagination();
  }, [currentPage]);

  const navigate = useNavigate();

  return (
    <div className="Joblist" data-testid="Joblist">
      <div className="background">
        <Helmet>
          <title> {strings.pageTitle} </title>
        </Helmet>

        <BreadCrumbs
          items={[
            {
              text: `${strings.breadCrumbsText}`,
              link: "/jobs",
            },
          ]}
        />
      </div>
      <div className="container main">
        <div className="section-1">
          <div className="wrapper">
            <div className="left">
              <div className="title-text">{strings.section1Title}</div>
              <div className="subtitle-text">
                {strings.section1Subtitle} <br /> {strings.section1Subtitle2}
              </div>
            </div>
            <div className="image">
              <img
                className="block"
                src="/assets/images/deco_arrow_about.svg"
              ></img>
              <img className="block" src="/assets/images/about.png"></img>

              <div className="striped_rect"></div>
            </div>
          </div>
        </div>
        <div className="badges">
          <Badge
            background=" #D6E9FF8A"
            image="/assets/images/mail.png"
            title={strings.badge1Title}
            title_color="#004BA3"
            text={strings.badge1Text}
          >
            <a href="mailto:info@attivagroup.it">
              <div className="cyan">info@attivagroup.it</div>
            </a>
          </Badge>
          <Badge
            background="#E2F8E9"
            image="/assets/images/phone.png"
            title={strings.badge2Title}
            title_color="#1EBD53"
            text={strings.badge2Text}
          >
            <a href="tel:+39 045 853 1464">
              <div className="cyan">+39 045 853 1464</div>
            </a>
          </Badge>
        </div>
        <div className="joblisting">
          <div className="title-box">
            <div className="title">{strings.jobListTitle}</div>
            <div className="striped_rect"></div>
          </div>
          <div className="joblisting-buttons">
            <Radio.Group
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <Radio.Button value={0}>{strings.jobListTypeAll}</Radio.Button>
              {typeof jobTypes !== "undefined" &&
                jobTypes.length > 0 &&
                jobTypes.map((jobType, index) => (
                  <Radio.Button value={jobType.id} key={index}>
                    {jobType[addLanguageSuffix("name")]}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </div>
          <div className="jobs">
            {(typeof paginatedJobs == "undefined" ||
              paginatedJobs.length == 0) && (
              <div>
                <div className="no-jobs">{strings.jobListNoJob}</div>
              </div>
            )}
            {typeof paginatedJobs !== "undefined" &&
              paginatedJobs.length > 0 &&
              typeof jobTypes !== "undefined" &&
              jobTypes.length > 0 && (
                <div className="job-row">
                  {paginatedJobs.slice(0, 4).map((job, index) => (
                    <JobCard
                      key={index}
                      specialization={{
                        name: jobTypes.find(
                          (jobType) => jobType.id === job.job_type
                        )[addLanguageSuffix("name")],
                        //localizare
                        color: "#3F95F9",
                        background: "#E6F2FF",
                      }}
                      location={
                        job[addLanguageSuffix("location_country")] +
                        ", " +
                        job[addLanguageSuffix("location_exact")]
                      }
                      title={cutString(job[addLanguageSuffix("title")])}
                      tags={
                        job[addLanguageSuffix("tags")]
                          ? job[addLanguageSuffix("tags")]
                              .split("\\n")
                              .filter((tag) => tag)
                          : null
                      }
                      jobType={{
                        id: job.id,
                      }}
                      onClick={() => {
                        navigate(`/uniqueJob/${job.id}`, { replace: true });
                      }}
                      onClickr={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      jobDisplayType="uniqueJob"
                    />
                  ))}
                </div>
              )}
          </div>
          <div className="joblisting-pagination">
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              pageSize={jobsPerPage}
              total={
                typeof filteredJobs !== "undefined" && filteredJobs.length > 0
                  ? filteredJobs.length
                  : 0
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Joblist.propTypes = {};

Joblist.defaultProps = {};

export default Joblist;
