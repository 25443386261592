import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    aboutUs: "About us",
    jobs: "Jobs",
    contact: "Contact",
  },
  ro: {
    aboutUs: "Despre noi",
    jobs: "Locuri de muncă",
    contact: "Contact",
  },
  it: {
    aboutUs: "Chi siamo",
    jobs: "Lavora con noi",
    contact: "Contatto",
  },
});

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [local, setLocal] = useState(strings);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLocal(strings.setLanguage(lang));
    }
  }, []);

  return (
    <div className="navbar-wrapper">
      <div className="container">
        <div className="Navbar">
          <div className="left">
            <Link to={"/"}>
              <img className="logo" src="/assets/images/logo.png"></img>
            </Link>
          </div>
          <div className="center desktop">
            <Link to={"/about"}>
              <div className="item"> {strings.aboutUs} </div>
            </Link>
            <Link to={"/jobs"}>
              <div className="item"> {strings.jobs}</div>
            </Link>
            <Link to={"/contact"}>
              <div className="item"> {strings.contact} </div>
            </Link>
          </div>
          <div className="right desktop">
            {localStorage.lang === "en" ? (
              <>
                <input
                  className="lang_button"
                  type="button"
                  value="IT"
                  onClick={() => {
                    localStorage.setItem("lang", "it");
                    navigate(0);
                  }}
                />
                <input
                  className="lang_button"
                  type="button"
                  value="RO"
                  onClick={() => {
                    localStorage.setItem("lang", "ro");
                    navigate(0);
                  }}
                />
              </>
            ) : localStorage.lang === "ro" ? (
              <>
                <input
                  className="lang_button"
                  type="button"
                  value="IT"
                  onClick={() => {
                    localStorage.setItem("lang", "it");
                    navigate(0);
                  }}
                />
                <input
                  className="lang_button"
                  type="button"
                  value="EN"
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    navigate(0);
                  }}
                />
              </>
            ) : (
              <>
                <input
                  className="lang_button"
                  type="button"
                  value="EN"
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    navigate(0);
                  }}
                />
                <input
                  className="lang_button"
                  type="button"
                  value="RO"
                  onClick={() => {
                    localStorage.setItem("lang", "ro");
                    navigate(0);
                  }}
                />
              </>
            )}

            <a href="tel:+39 045 853 1464" className="tel_container">
              <img
                className="tel_icon"
                src="/assets/images/icon_telephone.png"
              ></img>
              <div className="tel_text">+39 045 853 1464</div>
            </a>
          </div>
          <div className="mobile right" onClick={toggleMenu}>
            <MenuOutlined />
          </div>
          <div className={"mobile-menu " + (menuOpen ? "open" : "closed")}>
            <div className={"links mobile "}>
              <Link to={"/about"}>
                <div className="item"> {strings.aboutUs} </div>
              </Link>
              <Link to={"/jobs"}>
                <div className="item"> {strings.jobs}</div>
              </Link>
              <Link to={"/contact"}>
                <div className="item"> {strings.contact} </div>
              </Link>
            </div>
            <div className="buttons mobile">
              {localStorage.lang === "en" ? (
                <>
                  <input
                    className="lang_button"
                    type="button"
                    value="IT"
                    onClick={() => {
                      localStorage.setItem("lang", "it");
                      navigate(0);
                    }}
                  />
                  <input
                    className="lang_button"
                    type="button"
                    value="RO"
                    onClick={() => {
                      localStorage.setItem("lang", "ro");
                      navigate(0);
                    }}
                  />
                </>
              ) : localStorage.lang === "ro" ? (
                <>
                  <input
                    className="lang_button"
                    type="button"
                    value="IT"
                    onClick={() => {
                      localStorage.setItem("lang", "it");
                      navigate(0);
                    }}
                  />
                  <input
                    className="lang_button"
                    type="button"
                    value="EN"
                    onClick={() => {
                      localStorage.setItem("lang", "en");
                      navigate(0);
                    }}
                  />
                </>
              ) : (
                <>
                  <input
                    className="lang_button"
                    type="button"
                    value="EN"
                    onClick={() => {
                      localStorage.setItem("lang", "en");
                      navigate(0);
                    }}
                  />
                  <input
                    className="lang_button"
                    type="button"
                    value="RO"
                    onClick={() => {
                      localStorage.setItem("lang", "ro");
                      navigate(0);
                    }}
                  />
                </>
              )}
              <div className="tel_wrapper">
                <a href="tel:+39 045 853 1464" className="tel_container">
                  <img
                    className="tel_icon"
                    src="/assets/images/icon_telephone.png"
                  ></img>
                  <div className="tel_text">+39 045 853 1464</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
