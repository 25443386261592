import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Carousel.scss";
import CrossfadeImage from "react-crossfade-image";
const timer = (ms) => new Promise((res) => setTimeout(res, ms));

const Carousel = () => {
  const [[one, two, three, four], setNumbers] = useState([1, 2, 3, 4]);
  const nrPhotos = "6";
  const changeNumbers = () => {
    if (four < nrPhotos - 1) {
      return setNumbers([three, four, four + 1, four + 2]);
    } else {
      return setNumbers([three, four, 1, 2]);
    }
  };

  useEffect(() => {
    const timer = setInterval(changeNumbers, 2500);

    return () => clearInterval(timer);
  });
  return (
    <div className="Carousel">
      <div className="striped_rect"></div>
      <div className="arrow_ver">
        <img src="/assets/images/deco_arrow_ver.svg"></img>
      </div>
      <div className="arrow_hor">
        <img src="/assets/images/deco_arrow_hor.svg"></img>
      </div>

      <div className="picture-r one">
        <CrossfadeImage
          src={"/assets/images/home_pics/" + one + ".png"}
        ></CrossfadeImage>
      </div>
      <div className="picture-l one">
        <CrossfadeImage
          src={"/assets/images/home_pics/" + two + ".png"}
        ></CrossfadeImage>
      </div>
      <div className="picture-r two">
        <CrossfadeImage
          src={"/assets/images/home_pics/" + three + ".png"}
        ></CrossfadeImage>
      </div>
      <div className="picture-l two">
        <CrossfadeImage
          src={"/assets/images/home_pics/" + four + ".png"}
        ></CrossfadeImage>
      </div>
    </div>
  );
};

Carousel.propTypes = {};

Carousel.defaultProps = {};

export default Carousel;
